/* ================================================================= */
/* Info Blocks Section */
/* ================================================================= */

.home_info_blocks_container {
  margin: 0 auto;
  max-width: 84rem;
}

.home_info_blocks__column {
  flex: 1 1 auto !important;
  max-width: 24rem !important;
}

.home_info_block {
  display: flex;
  flex-direction: column;
}

.home_info_block__icon {
  background: linear-gradient(180deg, #3d8ccc 0%, #1f5b8c 100%);
  width: fit-content;
  border-radius: 100%;
  width: 135px;
  height: 135px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 2rem;
}

@media screen and (min-width: 576px) {
  .home_info_block__title {
    margin-bottom: 0.5rem !important;
  }

  .home_info_blocks__column {
    flex-basis: min-content !important;
  }
}

/* 820px is quite specific here as the minimum width which allows for the 3 titles to fit on a single line -- will need
 * changed if the titles are changed
*/
@media screen and (min-width: 820px) {
  .home_info_block__title {
    white-space: nowrap;
  }
}

/* ================================================================= */
/* 3 Cards Section */
/* ================================================================= */

#home-page-3cards {
  width: 90%;
  margin: 0 auto;
}

#home-page-3cards .ant-card-meta-title {
  font-family: 'gBold', sans-serif;
  margin-bottom: 2px;
  font-size: 16px;
}

#home-page-3cards .ant-card-meta-description {
  font-family: 'gLight', sans-serif;
  color: #000000e0 !important;
}

#home-page-3cards .ant-card.ant-card-bordered {
  border-radius: 10px !important;
}

#home-page-3cards .ant-card-cover img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#home-page-3cards .ant-card.ant-card-bordered {
  box-shadow: 2px 2px 10px #969696;
  transition: all 0.2s;
}

#home-page-3cards .ant-card.ant-card-hoverable:hover {
  transform: scale(1.0156);
}

/* ================================================================= */
/* Featured Fisheries Section */
/* ================================================================= */

.fishery-slider-contiainer {
  margin-left: 20px;
}

/* ================================================================= */
/* Quick Links Section */
/* ================================================================= */

@media (min-width: 768px) {
  #home-page-featured-fisheries {
    padding-bottom: 2.5rem !important;
  }
  .quick-links-container {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .quick-links-container-wrapper {
    display: flex;
    justify-content: center;
  }

  .quick-links-container {
    flex-grow: 1;
  }
}
.sb-pro-ad-container {
  margin-top: 10px;
  position: relative;
  margin-bottom: 20px;
  padding: 1.5rem 1.5rem !important;
}
.sb-pro-ad-container .learn-more-button {
  border-radius: 8px;
  border: 1px solid #aad2f2;
  background: #1f5b8c;
  color: white;
  height: fit-content;
  padding: 5px 20px;
  font-size: 18px;
  font-family: 'gBold';
}
.sb-pro-ad-container .learn-more-button:hover {
  background: #174a73;
}
.info-box .lake-container {
  color: #062640;
}
.info-box .lake-container:hover {
  color: #3d8ccc !important;
}
.info-box .lake-title {
  font-size: 20px;
}
.info-box .lake-img {
  width: 100%;
  height: 297px;
  border-radius: 10px;
}
.info-box .lake-img img:hover {
  transform: scale(1.1);
}
.info-box .lake-img img {
  transition: all ease-in-out 0.2s;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}
.iphone-sb-image {
  position: absolute;
  width: 95%;
  bottom: -462px;
  left: 50%;
  transform: translate(-50%, 0%); /* Optional */
}
.venue-type-container {
  position: relative;
  height: 100%;
  transition: all 0.2s;
}
.venue-type-container:hover {
  transform: scale(1.03);
}
.venue-type-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.venue-type-container .text-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  padding: 10px 18px;
  border-radius: 20px;
  color: #fff;
  font-family: 'gSemiBold';
  font-size: 30px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 81.5%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
.mobile-app-container {
  border-radius: 20px;
  display: flex;
  /* gap: 2rem; */
  border: 1px solid #d3d3d3;
}
.mobile-app-container .benefits {
  flex: 1;
  min-width: 450px;
}
.search-icon-button svg {
  width: 100%;
  height: 50%;
}

.img-container {
  /* border-radius: 20px; */
  flex: 1;
  min-width: 300px;
  width: 100%;
}
.img-container img {
  /* border-radius: 20px; */
  width: 100%;
  border-radius: 20px 0 0 20px;
  object-fit: cover;
  height: 100%;
}
/* ================================================================= */
/* Call to Action Section */
/* ================================================================= */

.home-page-last-content-img-right {
  background: white;
  padding: 20px 12px;
  width: 75px;
  border-radius: 20px;
  /* top: -13px; */
  text-align: center;
  position: relative;
}

.home-page-last-content-img-right.boy {
  padding: 20px !important;
}

.home-page-last-content-left {
  padding-right: 40px;
}

.home-page-last-content-contact-btn {
  padding: 17px 60px;
  background: #287bc4;
  color: white;
  font-family: 'gBold', sans-serif;
  font-size: 20px;
  border-radius: 10px;
}

.home-page-last-content-contact-btn:hover {
  color: white;
}

.home-page-last-content-contact-btn.fishery {
  background: #3cb371 !important;
}

.love-angling-title {
  font-size: 38px;
  margin-bottom: 1rem;
}
.list-lake-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 20px;
}
.list-lake-bg-img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-lake-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 20px 50px;
  font-size: 46px;
  font-family: 'gBold';

  border-radius: 20px;
  background: linear-gradient(
    261deg,
    rgba(170, 210, 242, 0) 40.44%,
    #030a10 91.62%
  );
}
.list-lake-button {
  margin-top: 15px !important;
  border-radius: 8px !important;
  border: 1px solid #aad2f2 !important;
  background: #1f5b8c !important;
  font-size: 18px !important;
  font-family: 'gBold';
  padding: 5px 30px !important;
  width: fit-content !important;
  height: fit-content !important;
  color: white !important;
}
.list-lake-button:hover {
  background: #174a73 !important;
}
.app-mobile-icons {
  display: none;
}

@media only screen and (min-width: 500px) and (max-width: 746px) {
  .mobile-app-container .QR,
  .mobile-app-container .QR img {
    width: 80%;
    margin: auto;
    display: flex;
  }
}
@media only screen and (max-width: 576px) {
  .love-angling-title {
    font-size: 3.8em;
    line-height: 1.25;
  }
  .venue-type-container .text-container {
    font-size: 28px;
  }
  .list-lake-button {
    font-size: 22px !important;
  }
  .mobile-app-container .benefits {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-app-container .text-center {
    text-align: center;
  }
  .fishery-slider-contiainer {
    margin-left: 0;
  }
  .fishery-slider-contiainer > h2 {
    font-size: 32px !important;
  }
  .fishery-slider-contiainer {
    margin-left: 0;
  }
  .list-lake-bg-img {
    filter: brightness(0.45);
  }
  .list-lake-text {
    background: none;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 38px;
  }

  .info-box .lake-img img:hover {
    transform: scale(1.05);
  }
}

.home-page-last-left {
  padding: 50px;
  background: #3fb975;
  color: white;
  border-radius: 10px;
  margin-right: 20px;
  min-height: 100%;
}

.home-page-last-right {
  padding: 50px;
  background: #e6e6e6;
  color: white;
  border-radius: 10px;
  min-height: 100%;
}

.calling-angler-container {
  width: 80%;
  height: 80%;
  margin: 0 auto;
}
.all-in-one-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 50px;
  background: linear-gradient(118deg, #2b5678 25.72%, #3d8ccc 85.94%);
  padding: 20px 50px;
  margin: 30px 0 0;
}
.all-in-one-container .phone-sb-image {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 990px) and (max-width: 1060px) {
  .all-in-one-container {
    display: flex;
    padding: 20px 50px;
    flex-direction: row;
  }
}
@media only screen and (max-width: 990px) {
  .all-in-one-container .text-wrapper {
    margin: auto;
    width: fit-content;
  }
}
@media only screen and (min-width: 1060px) {
  .all-in-one-container {
    display: flex;
    width: 90%;
    flex-direction: row;
    margin: 150px 0 70px;
  }
  .all-in-one-container .phone-sb-image {
    position: absolute;
    width: 120%;
    min-width: 620px;
    bottom: -95px;
    left: 50%;
    transform: translate(-33%, 0%);
  }
}
@media only screen and (max-width: 500px) {
  .QR,
  .mobile-app-container .text {
    display: none;
  }
  .mobile-app-container .text-center {
    text-align: center;
  }

  .app-mobile-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
  }
  .all-in-one-container {
    display: flex;
    padding: 20px 30px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 500px) {
  .sb-bullets {
    margin-left: 60px !important;
  }
}

.home-page-last-left {
  padding: 50px;
  background: #3fb975;
  color: white;
  border-radius: 10px;
  margin-right: 20px;
  min-height: 100%;
}

.home-page-last-right {
  padding: 50px;
  background: #e6e6e6;
  color: white;
  border-radius: 10px;
  min-height: 100%;
}

.calling-angler-container {
  width: 80%;
  height: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 800px) {
  .home-page-last-left,
  .home-page-last-right {
    padding: 10px;
    min-height: 550px;
  }
  .img-container img {
    border-radius: 20px 20px 0 0;
  }
  .home-page-last-content-left {
    padding-right: 5px;
  }

  .home-page-last-content-contact-btn {
    margin-bottom: 10px;
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .home-page-last-left {
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .sb-pro-ad-container {
    margin-top: 140px;
    position: relative;
    margin-bottom: 150px;
    padding: 1.5rem 2.5rem 1.5rem 0 !important;
  }

  .img-container {
    max-width: 425px;
  }
  .angler-content-container {
    height: 30rem;
  }
  .info-box {
    margin: 0 100px !important;
  }
}

@media screen and (min-width: 1350px) {
  .angler-content-container {
    height: 26rem;
  }
}

@media screen and (min-width: 1650px) {
  .angler-content-container {
    height: 22rem;
  }
}
