.current-0-2-9.current-d1-0-2-15 {
  font-size: 30px !important;
}

.day-0-2-22 {
  margin: 2px !important;
}

.icon-0-2-26 {
  padding-top: 2px;
}

.icon-0-2-26 svg {
  height: 20px !important;
}

.desc-0-2-24.desc-d3-0-2-30 {
  margin: 0px !important;
  font-size: 12px !important;
}

.info-0-2-12.info-d4-0-2-18 div:first-child {
  display: inline-block !important;
  float: left !important;
}

.info-0-2-12.info-d4-0-2-18 div:last-child {
  display: inline-block !important;
  float: right !important;
}
