/* ================================================================= */
/* Featured Articles Section */
/* ================================================================= */

#home-page-article {
  background: #0d3759;
  width: 100%;
  padding: 3rem 2.5rem !important;
  color: #83c1dc;
}

.article-wrapper {
  background: #174a73;
  border-radius: 1rem;
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.article-wrapper:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 4px 1px rgb(0 0 0 / 29%);
}

.featured-articles-layout-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.featured-articles-layout-container .article-wrapper {
  min-width: 0;
}

@media (min-width: 576px) {
  .featured-articles-layout-container {
    grid-template-columns: 1fr 1fr;
  }

  .featured-articles-layout-container .article-wrapper:first-of-type {
    grid-column: span 2;
  }
}

@media (min-width: 768px) {
  .featured-articles-layout-container {
    grid-template-rows: 1fr 0.5fr 1fr;
  }

  .featured-articles-layout-container .article-wrapper:first-of-type {
    grid-row: span 2;
  }
}

@media (min-width: 992px) {
  .featured-articles-layout-container {
    grid-template-columns: 1fr 0.5fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.article-angler-container {
  padding: 1rem 0;
  margin: auto;
}

.home-explore-all {
  flex-grow: 1;
  padding: 10px 20px;
  margin: 10px auto 2rem;
  border: 1px solid #aad2f2;
  background: #1f5b8c;
}
.home-explore-all:hover {
  background: #174a73;
}

@media (min-width: 660px) {
  .home-explore-all {
    position: absolute;
    top: 10px;
    border-radius: 8px;
    right: 1.5rem;
    min-width: 9rem;
    width: fit-content;
    white-space: nowrap;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.home-explore-all a:hover {
  color: white !important;
}

.article-layout-image {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.article-layout-title {
  padding: 10px;
}

.rest-of-the-home-content-wrapper {
  margin: 0;
}

.home-page-article-image {
  position: relative;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #464646;
  width: 100%;
  margin: 0 auto;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  flex-grow: 1;
  aspect-ratio: 2 / 1;
  min-height: 10rem; /* Fallback for browsers where aspect-ratio is not supported */
}
