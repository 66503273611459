.member-sign-up__layout {
  display: grid;
  place-content: center;
  align-content: center;
  min-height: 100dvh;
  padding-top: 2rem;
}

.member-sign-up__inner {
  background: #fafbfd;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100vw;
}

.member-sign-up__logo {
  width: 17rem;
  max-width: 90%;
  margin-bottom: 2rem;
  margin-inline: auto;
}

.member-sign-up__icon {
  font-size: 4rem;
}

.member-sign-up__layout .mobile-login-btn {
  background: #1f5b8c !important;
}

.member-sign-up__inner p {
  font-family: 'gRegular', sans-serif;
  font-size: 18px;
  margin-bottom: 0;
}

.waitlist-login {
  max-width: 30rem;
  margin-inline: auto;
}

.waitlist-login .login-menu-items button {
  border-radius: 0.5rem !important;
  width: 100% !important;
  font-size: 20px !important;
}

.member-sign-up__inner .ant-form-item-control-input {
  width: 30rem;
  max-width: calc(100vw - 5rem);
}

.member-sign-up__inner .ant-form-item-control-input-content > * {
  border-radius: 0.5rem;
}

.member-sign-up__inner .ant-form-item-label * {
  font-family: 'gSemiBold', sans-serif;
  color: #606060;
}

.member-sign-up__inner .ant-input[disabled] {
  color: #bcbcbc;
}

.member-sign-up__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 1rem;
}

.member-sign-up__buttons .ant-btn {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .member-sign-up__buttons .ant-btn {
    max-width: 250px;
  }
}

@media screen and (min-width: 768px) {
  .member-sign-up__layout {
    padding-block: 2rem;
  }

  .member-sign-up__inner {
    width: fit-content;
    padding: 2rem 5rem;
    max-width: 60rem;
    border-radius: 30px;
  }
}
