:root {
  --lightbox-header-height: 5rem;
}

.image-lightbox-modal {
  max-width: 100vw;
}

.image-lightbox-modal,
.image-lightbox-modal > div,
.image-lightbox-modal .ant-modal-content,
.image-lightbox-modal .ant-modal-body {
  margin: 0 !important;
  padding: 0;
  height: 100%;
  max-width: unset !important;
}

.image-lightbox-modal .ant-modal-header {
  height: var(--lightbox-header-height);
}

.image-lightbox-modal .ant-modal-close {
  left: 0 !important;
  padding: 0.5rem;
}

.image-lightbox-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
}

.image-lightbox-modal .ant-modal-body,
.image-lightbox-modal .ant-modal-header {
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  border: none;
}

.image-lightbox-modal .image_slider {
  width: 100%;
}

.image-lightbox-modal img {
  margin: auto auto;
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}
.image-lightbox-modal--has-title img {
  max-height: calc(100vh - var(--lightbox-header-height));
}

.image-lightbox-modal .slider .slider-content {
  height: fit-content !important;
  margin: auto !important;
  display: flex;
}

.image-lightbox-modal li {
  display: flex;
}

.image-lightbox-modal .ant-modal-body div:not(.slider-count),
.image-lightbox-modal .ant-modal-body ul,
.image-lightbox-modal .ant-modal-body li {
  height: 100%;
}
