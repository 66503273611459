/* ================================================================= */
/* Masthead */
/* ================================================================= */
.masthead {
  position: relative;
  height: fit-content;
  min-height: calc(100vh - 3rem);

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  margin-bottom: 1rem;
  --home-header-height: 6rem;
}

/**
 keyframes from https://www.devtwins.com/blog/css-cross-fading-images
 */
@keyframes mastheadBackgroundFadeInOut {
  0% {
    opacity: 1;
  }
  18% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  93% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
  Animation durations:
  a = image display duration = 5s
  b = fade transition duration = 2s
  n = number of images = 4
  t = n(a+b) = total duration = 28s
 */
.masthead__background {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(0.7);
  top: 0;
  z-index: 0;
  background-color: #464646; /* Background color that will be displayed while the image is loading */
  animation: mastheadBackgroundFadeInOut ease-in-out 42s infinite; /* Duration given by t */
}

.masthead__background:nth-of-type(1) {
  animation-delay: 35s; /* (n - 1)(a + b) */
}

.masthead__background:nth-of-type(2) {
  animation-delay: 28s; /* (n-2)(a + b) */
}
.masthead__background:nth-of-type(3) {
  animation-delay: 21s; /* (n-3)(a + b) */
}
.masthead__background:nth-of-type(4) {
  animation-delay: 14s; /* (n-2)(a + b) */
}
.masthead__background:nth-of-type(5) {
  animation-delay: 7s; /* (n-3)(a + b) */
}
.masthead__background:nth-of-type(6) {
  animation-delay: 0s; /* (n-4)(a + b) */
}

.masthead__content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 9% 9% 4rem;
  text-align: center;
  margin: auto 0;
  z-index: 0;
  height: max(calc(100% - 0.1rem), fit-content);
}

.masthead__content > * {
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  position: relative;
}

.masthead__logo {
  max-width: 4.5rem;
  max-height: 4.5rem;
  margin: 0 auto 2rem;
}

.masthead__title {
  font-size: 42px;
  line-height: 1.2;
}

.masthead__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 1rem;
  row-gap: 0.75rem;
  width: 100%;
  max-width: 39rem;
  margin: auto;
}

.masthead__buttons > * {
  display: flex;
  flex-grow: 1;
  width: unset;
  flex-basis: 14rem;
  min-width: fit-content;
}

.masthead__buttons .sb-search-input {
  max-width: unset !important;
}

.join-sbplus-btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0.5px solid #a7a7a7;
}

.create-account-button {
  border-radius: 8px !important;
  border: 1px solid #aad2f2 !important;
  background: #1f5b8c !important;
  color: white !important;
  padding: 10px 20px !important;
  height: auto !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.create-account-button:hover {
  background: #174a73 !important;
}
.browse-lake {
  color: #d9eeff !important;
  margin-left: 35px;
  font-size: 18px !important;
  display: flex;
  align-items: center;
  text-decoration-line: underline !important;
}
.browse-lake:hover {
  color: #8abce5 !important;
}
.trust-pilot {
  position: absolute;
  bottom: -63px;
}
.join-sbplus-btn-title {
  position: relative;
  z-index: 2;
}

.video-playback {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

/**
  Desktop Design
 */
@media only screen and (min-width: 576px) {
  .masthead {
    justify-content: flex-end;
    min-height: max(calc(95vh - 8rem), 700px);
    --home-header-height: 4rem;
    padding-top: var(--home-header-height);
    margin-bottom: 2rem;
  }

  .masthead__content {
    text-align: left;

    padding: 4rem 0;
    justify-content: center;
  }

  .masthead__content > * {
    padding-left: 0;
    padding-right: 0;
  }

  .masthead__title {
    font-size: 42px;
    margin-top: -8px;
  }

  .masthead__logo {
    display: none;
  }
}
.masthead-swimbooker-mobile-logo {
  display: none;
}
/**
  Increase font-size on larger screens
 */
@media only screen and (min-width: 768px) {
  .masthead__title {
    font-size: 58px;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 500px) {
  .masthead-swimbooker-mobile-logo {
    display: flex;
    position: absolute;
    top: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .masthead__background {
    filter: brightness(0.5);
  }
  .masthead__content.main_container_padding {
    padding: 0 1.5rem 1.5rem !important;
  }
  .buttons-container {
    flex-direction: column;
    gap: 20px;
  }
  .trust-pilot {
    display: none;
  }
  .trust-pilot img {
    width: 100%;
  }
  .create-account-button {
    width: 100%;
  }
  .browse-lake {
    display: flex;
    justify-content: center;
    margin-left: 0;
    width: 100%;
  }
}

/**
  Decrease whitespace on short screens (vertically)
 */
@media only screen and (max-height: 800px) {
  .masthead__content {
    gap: 20px;
  }
}

/**
  Decrease font-size on very small screens
 */
@media only screen and (max-width: 350px) {
  .masthead__title {
    font-size: 34px;
  }
}

/**
  Decrease font-size on very very small screens to prevent single-word-overflow
 */
@media only screen and (max-width: 233px) {
  .masthead__title {
    font-size: 1.5em;
  }
}
