.admin-login-page {
  display: flex;
  height: 100vh;
}

.login-inside-form-content {
  padding: 40px 0px !important;
}

#admin-login-form-content {
  margin: auto;
  width: 50%;
  min-width: 500px;
  background: white;
  border-radius: 15px;
  max-width: 900px;
}

#admin-login-form-content .admin-logo-text {
  position: relative;
  top: 6px;
}

.admin-login-form-wrapper {
  display: flex;
}

#admin-login-form-content .admin-login-form-items {
  margin: auto;
  width: 50%;
  min-width: 330px;
}

.admin-login-btn {
  width: 100%;
  border: none !important;
  border-radius: 5px !important;
  height: 45px !important;
  font-size: 19px !important;
}

#admin-login-form-content .admin-login-form-items .ant-input {
  font-size: 16px;
}

#admin-login-form-content .admin-login-form-items input {
  background: #fcfcfc !important;
  margin-left: 0px;
}

#admin-login-form-content .admin-login-form-items input.email {
  height: 38px;
}

#admin-login-form-content .admin-login-form-items .admin-secret input {
  height: 28px;
}

#admin-login-form-content .contact-us-info-admin-login-wrapper {
  display: flex;
}

#admin-login-form-content .contact-us-info-admin-login-content {
  width: 50%;
  margin: 0 auto;
}

.back-to-login-btn {
  width: 100%;
  border: none !important;
  border-radius: 5px !important;
  height: 45px !important;
  font-size: 19px !important;
  background: #a7a7a7 !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
