table {
  border-collapse: collapse;
  border-spacing: 0;
}

.grey3 {
  color: #333;
}

.dark-slate-blue-bg {
  background: #17365e !important;
}

.seagreen {
  color: seagreen !important;
}

.seagreen-bg {
  background: seagreen !important;
}

.dark-slate-blue {
  color: #17365e;
}

.green-variant {
  color: #1b5735;
}

.green-variant2 {
  color: #178355 !important;
}

.light-green-variant {
  color: #28a817 !important;
}

.green-variant-btn-bg {
  background: #1b7e25 !important;
}

.green-variant-2 {
  color: #2ec07a !important;
}

.color-inherit {
  color: inherit !important;
}

th {
  border: 1px solid #000000;
}

td {
  border: 1px solid #000000;
}

.bold-content {
  font-weight: 600 !important;
}

.sb-font-11 {
  font-size: 11px !important;
}

.mg-0 {
  margin: 0 !important;
}
.mg-5 {
  margin: 5px !important;
}
.mg-10 {
  margin: 10px !important;
}
.mg-20 {
  margin: 20px !important;
}

.sb-align-right {
  text-align: right;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-center {
  display: grid !important;
  place-content: center;
  place-items: center;
}

.row-center {
  display: flex !important;
  align-items: center;
}

.row-baseline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  display: flex;
  flex-wrap: nowrap !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-05r {
  gap: 0.5rem;
}

.gap-1r {
  gap: 1rem;
}

.gap-2r {
  gap: 2rem;
}

.row-gap-05r {
  row-gap: 0.5rem;
}

.row-gap-1r {
  row-gap: 1rem;
}

.col-gap-05r {
  column-gap: 0.5rem;
}

.col-gap-1r {
  column-gap: 1rem;
}

.remove-bottom {
  margin: 0 !important;
}
.remove-bottom p {
  margin-bottom: 0 !important;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right;
}

.sb-align-center {
  text-align: center;
}

.sb-align-left {
  text-align: left;
}

/* Theme colors*/
/* #buttons  */
.light-green-btn,
.light-green {
  color: #3cb371;
}

.dark-green-btn,
.dark-green {
  color: #2e8b57;
}

.light-green-bg {
  background: #3cb371 !important;
}

.seagreen-100 {
  color: #c2f2de !important;
}
.seagreen-200 {
  color: #a1e5c9 !important;
}
.seagreen-300 {
  color: #6cd9ab !important;
}
.seagreen-400 {
  color: #60bf97 !important;
}
.seagreen-500 {
  color: #28a973 !important;
}
.seagreen-600 {
  color: #249968 !important;
}
.seagreen-700 {
  color: #1b734e !important;
}
.seagreen-800 {
  color: #095938 !important;
}
.seagreen-900 {
  color: #064028 !important;
}

.seagreen-bg-100 {
  background: #c2f2de !important;
}
.seagreen-bg-200 {
  background: #a1e5c9 !important;
}
.seagreen-bg-300 {
  background: #6cd9ab !important;
}
.seagreen-bg-400 {
  background: #60bf97 !important;
}
.seagreen-bg-500 {
  background: #28a973 !important;
}
.seagreen-bg-600 {
  background: #249968 !important;
}
.seagreen-bg-700 {
  background: #1b734e !important;
}
.seagreen-bg-800 {
  background: #095938 !important;
}
.seagreen-bg-900 {
  background: #064028 !important;
}

.light-blue-bg {
  background: #02a5be !important;
}

.alt-light-blue-bg {
  background: #4b9de0 !important;
}

.dark-green-bg {
  background: #2e8b57 !important;
}

.sb-blue-100 {
  color: #d9eeff !important;
}
.sb-blue-200 {
  color: #aad2f2 !important;
}
.sb-blue-300 {
  color: #8abce5 !important;
}
.sb-blue-400 {
  color: #3d8ccc !important;
}
.swimbooker-blue,
.sb-blue,
.sb-blue-500 {
  color: #2772af !important;
}
.sb-blue-600 {
  color: #1f5b8c !important;
}
.sb-blue-700 {
  color: #174a73 !important;
}
.sb-blue-800 {
  color: #0d3759 !important;
}
.sb-blue-900 {
  color: #062640 !important;
}

.sb-blue-bg-100 {
  background: #d9eeff !important;
}
.sb-blue-bg-200 {
  background: #aad2f2 !important;
}
.sb-blue-bg-300 {
  background: #8abce5 !important;
}
.sb-blue-bg-400 {
  background: #3d8ccc !important;
}
.sb-blue-bg,
.sb-blue-bg-500 {
  background: #2772af !important;
}
.sb-blue-bg-600 {
  background: #1f5b8c !important;
}
.sb-blue-bg-700 {
  background: #174a73 !important;
}
.sb-blue-bg-800 {
  background: #0d3759 !important;
}
.sb-blue-bg-900 {
  background: #062640 !important;
}

.red-100 {
  color: #ffb2b2 !important;
}
.red-200 {
  color: #e58a8a !important;
}
.red-300 {
  color: #d65d5d !important;
}
.red-400 {
  color: #cc3d3d !important;
}
.red-500 {
  color: #b22727 !important;
}
.red-600 {
  color: #a62121 !important;
}
.red-700 {
  color: #8c0000 !important;
}
.red-800 {
  color: #730000 !important;
}
.red-900 {
  color: #590000 !important;
}

.red-bg-100 {
  background: #ffb2b2 !important;
}
.red-bg-200 {
  background: #e58a8a !important;
}
.red-bg-300 {
  background: #d65d5d !important;
}
.red-bg-400 {
  background: #cc3d3d !important;
}
.red-bg-500 {
  background: #b22727 !important;
}
.red-bg-600 {
  background: #a62121 !important;
}
.red-bg-700 {
  background: #8c0000 !important;
}
.red-bg-800 {
  background: #730000 !important;
}
.red-bg-900 {
  background: #590000 !important;
}

.blue-2 {
  color: #1f85dd;
}

.dark-swim-blue {
  color: #1e5295;
}

.sb-black-blue {
  color: #444a58;
}

.light-swim-blue {
  color: #5db5fd;
}

/*
.light-blue-variant-bg{
	background: #eff6ff;
} */

.light-sb-bg {
  background-color: #5db5fd !important;
}

.grey {
  color: #a1a1a1;
}

.grey-100 {
  color: #f2f6fa !important;
}
.grey-200 {
  color: #dce1e5 !important;
}
.grey-300 {
  color: #ccd3d9 !important;
}
.grey-400 {
  color: #a1aab2 !important;
}
.grey-500 {
  color: #8a9299 !important;
}
.grey-600 {
  color: #737a80 !important;
}
.grey-700 {
  color: #5c6166 !important;
}
.grey-800 {
  color: #45494d !important;
}
.grey-900 {
  color: #1f2326 !important;
}

.sb-grey {
  color: #676767;
}

.sb-grey2 {
  color: #878787;
}

.light-grey {
  color: #f8f7f9;
}

.light-grey2 {
  color: #adadad;
}

.mp-light-grey {
  color: lightgrey;
}

.drop-shadows {
  color: #969696;
}

.sb-white,
.white {
  color: white !important;
}

.sb-dark-blue-bg {
  background: #1e5295 !important;
}

.sb-blue-gradient-bg {
  background: linear-gradient(0deg, #1b4c74 0%, #2772af 100%);
}

.sb-black-bg {
  background: #1f1f1f !important;
}

.sb-whitesmoke-bg {
  background: whitesmoke !important;
}

.grey-bg-100 {
  background: #f2f6fa !important;
}
.grey-bg-200 {
  background: #dce1e5 !important;
}
.grey-bg-300 {
  background: #ccd3d9 !important;
}
.grey-bg-400 {
  background: #a1aab2 !important;
}
.grey-bg-500 {
  background: #8a9299 !important;
}
.grey-bg-600 {
  background: #737a80 !important;
}
.grey-bg-700 {
  background: #5c6166 !important;
}
.grey-bg-800 {
  background: #45494d !important;
}
.grey-bg-900 {
  background: #1f2326 !important;
}

.sb-active-grey-bg {
  background: #ddddddb3 !important;
}

.sb-light-grey-bg {
  background: #fafafa;
}

.br50 {
  border-radius: 50% !important;
}

.br5 {
  border-radius: 5px !important;
}

.br8 {
  border-radius: 8px !important;
}

.br10 {
  border-radius: 10px !important;
}

.br15 {
  border-radius: 15px !important;
}

.br20 {
  border-radius: 20px !important;
}

.br30 {
  border-radius: 30px !important;
}

.brFull {
  border-radius: 200vw !important;
}

.sb-white-bg {
  background: white !important;
}

.black,
.sb-black {
  color: rgb(0, 0, 0, 0.85) !important;
}

.sb-light-black {
  color: #5d5d5d !important;
}

.w-16 {
  width: 16px;
}

.w-24 {
  width: 24px;
}

.w-50 {
  width: 50px;
}

.w-130 {
  width: 130px;
}

.w-fit {
  width: fit-content !important;
}

.w-full {
  width: 100% !important;
}

.min-w-0 {
  min-width: 0;
}

.max-w-60 {
  max-width: 60px;
}

.max-w-130 {
  max-width: 130px;
}

.max-w-230 {
  max-width: 230px;
}

.max-w-110 {
  max-width: 110px;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.display-inline {
  display: inline-flex;
}

.display-inline-block {
  display: inline-block;
}

.hide {
  display: none;
}

.show,
.display-block {
  display: block;
}

.opacity-50 {
  opacity: 50%;
}

.justify-center {
  display: flex !important;
  justify-content: center;
}
.justify-around {
  display: flex !important;
  justify-content: space-around;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  display: flex;
  justify-content: flex-end !important;
}

.justify-between {
  display: flex;
  justify-content: space-between !important;
}

.pd-0 {
  padding: 0 !important;
}

.sb-padding10,
.pd-10 {
  padding: 10px !important;
}

.pd-12 {
  padding: 12px !important;
}

.pd-15 {
  padding: 15px !important;
}

.pd-17 {
  padding: 17px !important;
}

.pd-20 {
  padding: 20px !important;
}

.pd-25 {
  padding: 25px !important;
}

.pd-30 {
  padding: 30px !important;
}

.pd-4 {
  padding: 4px !important;
}

.pd-5 {
  padding: 5px !important;
}

.pd-6 {
  padding: 6px !important;
}

.pd-7 {
  padding: 7px !important;
}

.pd-8 {
  padding: 8px !important;
}

.pd-2 {
  padding: 2px !important;
}
.pd-50 {
  padding: 50px !important;
}
.pd-65 {
  padding: 65px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.pd-top-0 {
  padding-top: 0 !important;
}

.pd-top-5 {
  padding-top: 5px !important;
}

.pd-top-7 {
  padding-top: 7px !important;
}

.pd-top-8 {
  padding-top: 8px !important;
}

.pd-top-10 {
  padding-top: 10px !important;
}

.pd-top-15 {
  padding-top: 15px !important;
}

.pd-top-16 {
  padding-top: 16px !important;
}

.pd-top-20 {
  padding-top: 20px !important;
}

.pd-top-30 {
  padding-top: 30px !important;
}

.pd-top-40 {
  padding-top: 40px !important;
}

.pd-bottom-0 {
  padding-bottom: 0 !important;
}

.pd-bottom-5 {
  padding-bottom: 5px !important;
}

.pd-bottom-7 {
  padding-bottom: 7px !important;
}

.pd-bottom-8 {
  padding-bottom: 8px !important;
}

.pd-bottom-10 {
  padding-bottom: 10px !important;
}

.pd-bottom-15 {
  padding-bottom: 15px !important;
}

.pd-bottom-16 {
  padding-bottom: 16px !important;
}

.pd-bottom-20 {
  padding-bottom: 20px !important;
}

.pd-bottom-30 {
  padding-bottom: 30px !important;
}

.pd-bottom-40 {
  padding-bottom: 40px !important;
}

.pd-bottom-100 {
  padding-bottom: 100px !important;
}

.pd-left-0 {
  padding-left: 0 !important;
}

.pd-left-5 {
  padding-left: 5px !important;
}

.pd-left-8 {
  padding-left: 8px !important;
}

.pd-left-10 {
  padding-left: 10px !important;
}

.pd-left-15 {
  padding-left: 15px !important;
}

.pd-left-16 {
  padding-left: 16px !important;
}

.pd-left-20 {
  padding-left: 20px !important;
}

.pd-left-25 {
  padding-left: 25px !important;
}

.pd-left-28 {
  padding-left: 28px !important;
}

.pd-left-30 {
  padding-left: 30px !important;
}

.pd-left-50 {
  padding-left: 50px !important;
}

.pd-right-0 {
  padding-right: 0 !important;
}

.pd-right-8 {
  padding-right: 8px !important;
}

.pd-right-10 {
  padding-right: 10px !important;
}

.pd-right-15 {
  padding-right: 15px !important;
}

.pd-right-16 {
  padding-right: 16px !important;
}

.pd-right-20 {
  padding-right: 20px !important;
}

.pd-right-25 {
  padding-right: 25px !important;
}

.pd-right-28 {
  padding-right: 28px !important;
}

.pd-right-30 {
  padding-right: 30px !important;
}

.pd-right-50 {
  padding-right: 50px !important;
}

.pd-right-5 {
  padding-right: 5px !important;
}

.sb-color-red {
  color: #ff2929 !important;
}

.sb-medium-grey {
  color: #5a5a5a;
}

.sb-box-shadow:hover {
  box-shadow: 0px 4px 4px #00000040;
}

.md-title {
  font-size: 18px;
}

.mg-bottom--1 {
  margin-bottom: -1px !important;
}

.mg-bottom-0 {
  margin-bottom: 0 !important;
}

.mg-bottom-2 {
  margin-bottom: 2px !important;
}

.mg-bottom-4 {
  margin-bottom: 4px !important;
}

.mg-bottom-5 {
  margin-bottom: 5px !important;
}

.mg-bottom-8 {
  margin-bottom: 8px !important;
}

.mg-bottom-10 {
  margin-bottom: 10px !important;
}

.mg-bottom-12 {
  margin-bottom: 12px !important;
}

.mg-bottom-15 {
  margin-bottom: 15px !important;
}

.mg-bottom-16 {
  margin-bottom: 16px !important;
}

.mg-bottom-18 {
  margin-bottom: 18px !important;
}

.mg-bottom-20 {
  margin-bottom: 20px !important;
}

.mg-bottom-25 {
  margin-bottom: 25px !important;
}

.mg-bottom-30 {
  margin-bottom: 30px !important;
}

.mg-bottom-40 {
  margin-bottom: 40px !important;
}

.mg-bottom-50 {
  margin-bottom: 50px !important;
}

.mg-bottom-100 {
  margin-bottom: 100px !important;
}

.mg-left-0 {
  margin-left: 0px !important;
}

.mg-left-5 {
  margin-left: 5px !important;
}

.mg-left-6 {
  margin-left: 6px !important;
}

.mg-left-7 {
  margin-left: 7px;
}

.mg-left-8 {
  margin-left: 8px;
}

.mg-left-10 {
  margin-left: 10px !important;
}

.mg-left-12 {
  margin-left: 12px;
}

.mg-left-15 {
  margin-left: 15px;
}

.mg-left-16 {
  margin-left: 16px !important;
}

.mg-left-20 {
  margin-left: 20px;
}

.mg-left-25 {
  margin-left: 25px;
}

.mg-left-30 {
  margin-left: 30px !important;
}

.mg-left-40 {
  margin-left: 40px;
}

.mg-left-45 {
  margin-left: 45px;
}

.mg-left-50 {
  margin-left: 50px;
}

.mg-left-55 {
  margin-left: 55px;
}

.mg-left-auto {
  margin-left: auto !important;
}

.mg-right-0 {
  margin-right: 0 !important;
}

.mg-right-5 {
  margin-right: 5px;
}

.mg-right-6 {
  margin-right: 6px !important;
}

.mg-right-7 {
  margin-right: 7px !important;
}

.mg-right-8 {
  margin-right: 8px !important;
}

.mg-right-10 {
  margin-right: 10px !important;
}

.mg-right-16 {
  margin-right: 15px;
}

.mg-right-15 {
  margin-right: 16px !important;
}

.mg-right-2 {
  margin-right: 2px !important;
}

.mg-right-20 {
  margin-right: 20px;
}

.mg-right-25 {
  margin-right: 25px;
}

.fw6 {
  font-weight: 600;
}

.mg-top-1 {
  margin-top: 1px !important;
}
.mg-top-0 {
  margin-top: 0 !important;
}

.mg-top-3 {
  margin-top: 3px;
}

.mg-top-4 {
  margin-top: 4px;
}

.mg-top-5 {
  margin-top: 5px;
}

.mg-top-10 {
  margin-top: 10px !important;
}

.mg-top--10 {
  margin-top: -10px !important;
}

.mg-top-15 {
  margin-top: 15px !important;
}

.mg-top-20 {
  margin-top: 20px !important;
}

.mg-top-25 {
  margin-top: 25px !important;
}

.mg-top-30 {
  margin-top: 30px !important;
}

.mg-top-40 {
  margin-top: 40px;
}

.mg-top-50 {
  margin-top: 50px;
}

.mg-top-65 {
  margin-top: 65px;
}

.mg-top-100 {
  margin-top: 100px;
}

.sb-center {
  text-align: center;
}

h1 {
  font-weight: 600;
}

.sb-subtitle {
  font-size: 12px;
}

.sb-title {
  font-size: 25px;
}

.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fl {
  float: left;
}

.fr {
  float: right !important;
}

.bg-black {
  background: #000000 !important;
}
.bg-grey {
  background: #676767 !important;
}
.bg-grey span {
  color: white !important;
}

.bg-light-grey {
  background-color: #bababa !important;
}

.bg-light-grey-2 {
  background-color: #a1a1a1 !important;
}

.bg-light-grey-3 {
  background-color: #e0e0e0 !important;
}

.bg-white {
  background: white !important;
}
.cap {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lake-availability-green {
  background: #83bc6e;
}

.lake-availability-red,
.bg-red {
  background: #d14a4a !important;
}

.lake-availability-yellow {
  background: #e5a248;
}

.lake-availability-grey {
  background: rgb(195 193 193 / 77%);
}

.cp {
  cursor: pointer;
}

.txt-underline {
  text-decoration: underline !important;
}

.txt-strikethrough {
  text-decoration: line-through !important;
}

.txt-italic {
  font-style: italic !important;
}

.txt-single-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.txt-nowrap {
  white-space: nowrap;
}

.txt-wrap {
  white-space: normal;
}

.txt-wrap {
  white-space: normal !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.mg-auto {
  margin: auto;
}

.mg-auto-l {
  margin-left: auto !important;
}

.mg-auto-lr {
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-area-display {
  white-space: pre-line;
}

.text-wrap {
  white-space: normal !important;
}

.sb-dark-blue-border {
  border-color: #1e5295 !important;
}

.sb-blue-border-100 {
  border-color: #d9eeff !important;
}
.sb-blue-border-200 {
  border-color: #aad2f2 !important;
}
.sb-blue-border-300 {
  border-color: #8abce5 !important;
}
.sb-blue-border-400 {
  border-color: #3d8ccc !important;
}
.sb-blue-border,
.sb-blue-border-500 {
  border-color: #2772af !important;
}
.sb-blue-border-600 {
  border-color: #1f5b8c !important;
}
.sb-blue-border-700 {
  border-color: #174a73 !important;
}
.sb-blue-border-800 {
  border-color: #0d3759 !important;
}
.sb-blue-border-900 {
  border-color: #062640 !important;
}

.grey-border-100 {
  border-color: #f2f6fa !important;
}
.grey-border-200 {
  border-color: #dce1e5 !important;
}
.grey-border-300 {
  border-color: #ccd3d9 !important;
}
.grey-border-400 {
  border-color: #a1aab2 !important;
}
.grey-border-500 {
  border-color: #8a9299 !important;
}
.grey-border-600 {
  border-color: #737a80 !important;
}
.grey-border-700 {
  border-color: #5c6166 !important;
}
.grey-border-800 {
  border-color: #45494d !important;
}
.grey-border-900 {
  border-color: #1f2326 !important;
}

.border-0 {
  border: 0 !important;
}

.border-1 {
  border: 1px solid;
}

.border-2 {
  border: 2px solid;
}

.border-top-1 {
  border-top: 1px solid;
}

.border-white {
  border-color: white !important;
}

.border-grey {
  border-color: #d1d1d1 !important;
}

.border-light-grey {
  border-color: #e7e7e7 !important;
}

.va-middle {
  vertical-align: middle;
}

.h-fit {
  height: fit-content !important;
}

.h-full {
  height: 100% !important;
}

.h-24 {
  height: 24px;
}

.h-30 {
  height: 30px;
}

.h-40 {
  height: 40px;
}

.min-h-screen {
  min-height: 100vh;
}

.lh-0 {
  line-height: 0;
}

.lh-1 {
  line-height: 1;
}

.lh-1\.1 {
  line-height: 1.1;
}

.lh-1\.25 {
  line-height: 1.25;
}

.lh-1\.3 {
  line-height: 1.3;
}

.lh-1\.33 {
  line-height: 1.33;
}

.lh-1\.66 {
  line-height: 1.66 !important;
}

/* Max screen size */
.ant-layout.layout._header_main_content,
.ant-layout-header.small.header,
.ant-layout-footer.sb-footer {
  margin: 0 auto;
  z-index: 12;
}

/*  */

.fs-1em {
  font-size: 1em;
}

.fs-2em {
  font-size: 2em;
}

.fs-2_5em {
  font-size: 2.5em;
}

.fs-3em {
  font-size: 3em;
}

.fs-3-5em {
  font-size: 3.5em;
}

.fs-4em {
  font-size: 4em;
}

.lottie-size-1 > svg {
  transform: scale(1) !important;
}

.lottie-size-1_3 > svg {
  transform: scale(1.3) !important;
}

.lottie-size-1_5 > svg {
  transform: scale(1.5) !important;
}

.lottie-size-1_7 > svg {
  transform: scale(1.7) !important;
}

.lottie-size-2 > svg {
  transform: scale(2) !important;
}

.lottie-size-3 > svg {
  transform: scale(3) !important;
}

.lottie-size-4 > svg {
  transform: scale(4) !important;
}

.lottie-size-4_5 > svg {
  transform: scale(4.5) !important;
}

.lottie-size-5 > svg {
  transform: scale(5) !important;
}

/* Used for button-like text to allow for accessible interactions (focusing etc.) */
.btn-clear {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.hover\:sb-blue-100:hover {
  color: #d9eeff !important;
}
.hover\:sb-blue-200:hover {
  color: #aad2f2 !important;
}
.hover\:sb-blue-300:hover {
  color: #8abce5 !important;
}
.hover\:sb-blue-400:hover {
  color: #3d8ccc !important;
}
.hover\:sb-blue-500:hover {
  color: #2772af !important;
}
.hover\:sb-blue-600:hover {
  color: #1f5b8c !important;
}
.hover\:sb-blue-700:hover {
  color: #174a73 !important;
}
.hover\:sb-blue-800:hover {
  color: #0d3759 !important;
}
.hover\:sb-blue-900:hover {
  color: #062640 !important;
}

.hover\:grey-100:hover {
  color: #f2f6fa !important;
}
.hover\:grey-200:hover {
  color: #dce1e5 !important;
}
.hover\:grey-300:hover {
  color: #ccd3d9 !important;
}
.hover\:grey-400:hover {
  color: #a1aab2 !important;
}
.hover\:grey-500:hover {
  color: #8a9299 !important;
}
.hover\:grey-600:hover {
  color: #737a80 !important;
}
.hover\:grey-700:hover {
  color: #5c6166 !important;
}
.hover\:grey-800:hover {
  color: #45494d !important;
}
.hover\:grey-900:hover {
  color: #1f2326 !important;
}

.hover\:sb-blue-bg-100:hover {
  background: #d9eeff !important;
}
.hover\:sb-blue-bg-200:hover {
  background: #aad2f2 !important;
}
.hover\:sb-blue-bg-300:hover {
  background: #8abce5 !important;
}
.hover\:sb-blue-bg-400:hover {
  background: #3d8ccc !important;
}
.hover\:sb-blue-bg-500:hover {
  background: #2772af !important;
}
.hover\:sb-blue-bg-600:hover {
  background: #174a73 !important;
}
.hover\:sb-blue-bg-700:hover {
  background: #174a73 !important;
}
.hover\:sb-blue-bg-800:hover {
  background: #0d3759 !important;
}
.hover\:sb-blue-bg-900:hover {
  background: #062640 !important;
}

.hover\:grey-bg-100:hover {
  background: #f2f6fa !important;
}
.hover\:grey-bg-200:hover {
  background: #dce1e5 !important;
}
.hover\:grey-bg-300:hover {
  background: #ccd3d9 !important;
}
.hover\:grey-bg-400:hover {
  background: #a1aab2 !important;
}
.hover\:grey-bg-500:hover {
  background: #8a9299 !important;
}
.hover\:grey-bg-600:hover {
  background: #737a80 !important;
}
.hover\:grey-bg-700:hover {
  background: #5c6166 !important;
}
.hover\:grey-bg-800:hover {
  background: #45494d !important;
}
.hover\:grey-bg-900:hover {
  background: #1f2326 !important;
}

.hover\:sb-blue-border-100:hover {
  border-color: #d9eeff !important;
}
.hover\:sb-blue-border-200:hover {
  border-color: #aad2f2 !important;
}
.hover\:sb-blue-border-300:hover {
  border-color: #8abce5 !important;
}
.hover\:sb-blue-border-400:hover {
  border-color: #3d8ccc !important;
}
.hover\:sb-blue-border-500:hover {
  border-color: #2772af !important;
}
.hover\:sb-blue-border-600:hover {
  border-color: #1f5b8c !important;
}
.hover\:sb-blue-border-700:hover {
  border-color: #174a73 !important;
}
.hover\:sb-blue-border-800:hover {
  border-color: #0d3759 !important;
}
.hover\:sb-blue-border-900:hover {
  border-color: #062640 !important;
}

.hover\:grey-border-100:hover {
  border-color: #f2f6fa !important;
}
.hover\:grey-border-200:hover {
  border-color: #dce1e5 !important;
}
.hover\:grey-border-300:hover {
  border-color: #ccd3d9 !important;
}
.hover\:grey-border-400:hover {
  border-color: #a1aab2 !important;
}
.hover\:grey-border-500:hover {
  border-color: #8a9299 !important;
}
.hover\:grey-border-600:hover {
  border-color: #737a80 !important;
}
.hover\:grey-border-700:hover {
  border-color: #5c6166 !important;
}
.hover\:grey-border-800:hover {
  border-color: #45494d !important;
}
.hover\:grey-border-900:hover {
  border-color: #1f2326 !important;
}
