/* Search Page Filter */
.search-filter-row {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  flex-wrap: nowrap !important;
  overflow-x: hidden;
  padding: 0 0 0.25rem;
  margin: 0;
  background: white;
  justify-content: space-around;
}

.search-filter-row > * {
  flex: 1 1 4rem;
}

.search-filter-row .search-filter-col {
  text-align: center;
  margin: 0;
  max-width: 8rem;
  display: flex;
  flex-direction: column;
}

.search-filter-row .search-filter-col p {
  padding-top: 0.4em;
  width: min-content;
  text-align: center;
  margin-inline: auto;
}

.search-filter-row .search-filter-col img {
  width: 3em;
}

.search-results-container .search-fishery-meta,
.fishery-divider-span {
  width: 100%;
}

.search-results-container {
  background: #f2f6fa;
  padding: 1rem;
  max-height: 100%;
}

.search-results-container > div {
  width: 100%;
}

.search-fishery-meta {
  border: none !important;
}

.search-fishery-meta .ant-card-body {
  padding: 0px !important;
}

.search-fishery-meta-left {
  padding: 0px 10px;
}

@media only screen and (max-width: 700px) {
  .search-fishery-meta-left {
    width: 200% !important;
  }
}

.search-carousel-image {
  width: 100%;
  height: 200px;
}

.search-list-image {
  border-radius: 10px;
}

#search-full-box {
  transition: all 0.3s;
}

.filter-cols .filter-icons {
  height: auto;
  background: none !important;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.filter-cols.inactive {
  height: auto;
  background: none !important;
  border: none;
  box-shadow: none;
}

.filter-cols:hover {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 8px -4px #969696 !important;
  transition:
    box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  border-radius: 20px;
}

.filter-cols.active {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 8px -4px #969696 !important;
  transition:
    box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  border-radius: 20px;
}

.filter-cols .filter-name {
  font-family: gLight;
  font-size: 13px;
  color: black;
  white-space: normal;
  min-width: 7ch;
  margin-bottom: 0;
}

.map-fishery-marker img:hover {
  cursor: pointer;
}

.fishery-meta-divider {
  border-bottom: 1.3px solid #5b5b5b73;
  width: 2.5em;
  margin: 7px 0px 7px 5px;
}

.species-icon {
  width: 3em;
}

.species-list {
  float: left;
  margin-left: 5px;
  width: 4.5em;
  text-align: center;
}

.species-title {
  position: relative;
  top: 22px;
  font-family: gSemiBold;
  font-size: 14px;
}

.species-name {
  margin: 0px;
  font-family: gLight;
  font-size: 12px;
}

.search-fishery-meta-right .list-avail-amenities {
  margin-top: 10px;
}

.search-fishery-meta-right .list-avail-amenities button {
  margin: 5px 0px 0px 5px;
  border-radius: 5px;
}

.search-fishery-meta-right .list-avail-amenities button span {
  font-family: gSemiBold;
  font-size: 12px;
  color: white;
}

.search-fishery-meta-right .search-meta-distance {
  float: right;
  word-spacing: -1.5px;
  font-family: gLight;
  font-weight: 300;
  font-size: 14px;
}

.tag-premium {
  position: absolute !important;
  top: 5px;
  left: 15px;
  z-index: 1;
  border-color: white !important;
}

.tag-bookable {
  position: absolute !important;
  top: 5px;
  left: 15px;
  z-index: 1;
}

#search-map-close-btn {
  display: none !important;
  position: absolute;
  z-index: 1;
  background: white;
  font-size: 18px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 7px;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

#search-map-close-btn:hover {
  background: #f5f5f5;
}

.favourite-fishery {
  position: absolute;
  top: 6px;
  right: 15px;
  font-size: 20px;
  color: white;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  transition: all 0.2s;
}

.favourite-fishery .anticon-heart {
  transition: all 0.2s;
}

.favourite-fishery .anticon-heart:hover {
  transform: scale(1.1);
}

.fav-fish-map {
  top: 15px !important;
  right: 7px !important;
}

/* Search inputs */
#search-radius:focus {
  box-shadow: none;
  outline: none !important;
}

.sb-search-input {
  display: inline-block !important;
  position: relative !important;
  border-radius: 40px !important;
  height: fit-content;
  transition: all 0.3s;
  box-shadow: none !important;
  outline: none !important;
}

.image-icon {
  height: 22px !important;
  width: 14px !important;
}

#search-full-box {
  /* min-width: 470px; */
  /* width: ; */
}

#search-input {
  width: 200px;
  z-index: 1;
  padding-left: 15px;
}

#search-input::placeholder {
  color: #b0b0b0;
  text-align: center;
  opacity: 1;
}

@media screen and (min-width: 576px) {
  #search-input::placeholder {
    text-align: left;
  }
  .pro-banner-header {
    display: flex !important;
    margin: auto;
    justify-content: center;
  }
}
.pro-banner-header {
  overflow-wrap: break-word;
}

#search-radius {
  width: 110px;
  right: 40px;
  padding-left: 46px;
  z-index: 0;
  padding: 0px;
  padding-left: 45px;
}

#search-border-input {
  width: 95px;
  /* right: 85px; */
  right: 53px;
  z-index: 0;
  z-index: -1;
  background: white;
}

/* Large header and search box transtition begin */
.header.large {
  height: 150px !important;
  transition: transform 0.2s;
  transition: all 0.2s;
}

.header.large #search-full-box {
  transform: scale(1.1);
  margin-top: 40px;
}

.sb-map-popover-content {
  width: min(350px, 98vw);
}

#sb-map-popover-content .ant-popover-inner-content {
  padding: 0px;
}

.map-popover-close-btn {
  cursor: pointer;
}

.sb-map-slider-carousel {
  border-radius: 15px 15px 0px 0px;
}

/* .slick-list */
.sb-search-btn {
  position: relative;
  /* right: 121px; */
  right: 92px;
  top: 1px;
  padding: 4px 0px;
  color: white;
  cursor: pointer;
}

#search-as-i-move {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  z-index: 1;
  background: white;
  font-size: 23px;
  border-radius: 1rem;
  text-align: center;
  padding: 0.75rem 1.5rem;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

#search-as-i-move > * {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

#search-as-i-move .ant-checkbox {
  top: 0;
}

#map-icon-guide {
  position: absolute;
  z-index: 1;
  font-size: 23px;
  height: 250px;

  padding: 0;
  bottom: 1rem;
  right: 1rem;
}

@media screen and (max-width: 780px) {
  #map-icon-guide {
    display: none;
  }
}

#sb-map .google-search-map-swimbooker {
  width: 100%;
  height: 130vh;
  position: absolute;
}

#sb-map {
  min-height: 100%;
  position: relative;
}

.search-meta-title {
  font-family: gSemiBold;
  font-size: 18px;
  margin: 0px 5px;
}

.search-meta-address {
  font-family: gLight;
  font-size: 13px;
}

.search-meta-description {
  font-family: gLight;
  font-weight: 300;
  margin: 0px 5px;
}

.react-switch {
  display: inline-block;
  vertical-align: middle;
}

#sb-map .gm-control-active.gm-fullscreen-control {
  display: none !important;
}

#sb-map .google-search-map-swimbooker {
  height: 100%;
}

@media all and (max-width: 1010px) {
  #search-full-box {
    text-align: center;
  }
}

#search-radius {
  display: none !important;
}

@media all and (max-width: 600px) {
  .list-avail-amenities {
    display: none !important;
  }
}

.map-search-meta-address {
  margin-left: 5px;
}

@media all and (max-width: 576px) {
  .search-fishery-meta-right {
    padding: 0px 10px;
  }

  .search-meta-title {
    margin: 5px 0 0 0;
  }

  .map-search-meta-address {
    margin-left: 0;
  }

  .search-meta-description {
    margin: 0;
  }

  .fishery-meta-divider {
    margin-left: 0;
  }
}

@media all and (max-width: 1100px) {
  .search-filter-row .search-filter-col img {
    width: 2.5em;
  }
}

/* New search bar */
.sb-search-input {
  text-align: left;
}

@media screen and (min-width: 576px) {
  .sb-search-input {
    max-width: 60% !important;
    min-width: 170px !important;
  }
}

.sb-search-input .ant-input-prefix {
  position: absolute;
  top: 0;
  left: 17px;
  height: 100%;
  z-index: 111;
}

.sb-search-input .ant-input-suffix {
  position: absolute;
  top: 3.3px;
  right: 13px;
  z-index: 111;
}

.sb-search-input .ant-input-suffix {
  height: calc(100% - 4px);
}

.sb-search-input .search-icon-button {
  aspect-ratio: 1;
}

@supports not (aspect-ratio: 1) {
  .sb-search-input .search-icon-button {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sb-search-input #search-input {
  position: relative;
  width: 80%;
  height: 32px;
  padding-left: 0;
  font-size: 16px;
}

@media (max-width: 370px) {
  .sb-search-input #search-input {
    font-size: 14px;
  }
}

.sb-search-input__tall #search-input {
  height: 47px;
  padding-left: 20px;
}

.sb-search-input__tall #search-input {
  font-size: 1.4em;
}

.sb-search-input__tall .search-icon-button.left {
  font-size: 1.4em;
  line-height: 1.6;
}

.sb-search-input__with_prefix {
  padding-left: 30px !important;
}

.sb-search-input__with_prefix #search-input {
  padding-left: 30px;
}

.search-icon-button {
  position: relative;
  cursor: pointer;
  right: -11px;
  top: -1px;
  /*padding: 9px;*/
  font-size: 17px;
  border-radius: 100vw;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.search-icon-button.left {
  right: 0;
  color: #b0b0b0;
}
.search-box .sb-search-input {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100% !important;
  border-radius: 90px !important;
  border: 1px solid #ededed;
  background: #f8fbff;
  box-shadow: -1px 1px 4px 0 rgba(161, 170, 178, 0.24) inset !important;
  padding: 4px;
}

.search-box .sb-search-input input {
  background: inherit;
}

.search-box .sb-search-input #search-input {
  width: 100%;
  margin-inline: 1.5rem;
}

.search-box .sb-search-input #search-input::placeholder {
  text-align: left;
}

.search-box .sb-search-input .ant-input-suffix {
  position: static;
  aspect-ratio: 1;
  display: flex;
  flex: 1 0 3.1rem; /* flex-basis controls button size */
  justify-content: flex-end;
  min-height: 100%;
}

.search-box .sb-search-input .ant-input-suffix,
.search-box .search-icon-button {
  max-height: 3rem;
  max-width: 3rem;
}

.search-box .search-icon-button {
  right: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(39, 115, 176, 0.81) 0%,
    #174a73 100%
  ) !important;
  filter: drop-shadow(-1px 1px 4px #d9d9d9);
}

.search-box .search-icon-button:hover {
  scale: 1.05;
}

/* New search bar */
.search-fishery-meta-left .slick-list,
.search-fishery-meta-left .sb-slider-carousel img {
  border-radius: 10px;
}
