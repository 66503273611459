.slider > div {
  position: relative;
}

.carousel-button-group {
  position: absolute;
  inset: 0;
  margin: 0 auto;

  width: 100%;
  max-width: 95vw;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  pointer-events: none;
}
.swiper-button-wrapper .carousel-button-group {
  position: absolute;
  inset: 0;
  margin: 0 auto;
  width: 101%;
  max-width: 95vw;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  pointer-events: none;
}
.swiper {
  width: 100%;
  height: 100%;
}
.catch-slider .swiper-slide {
  height: auto;
}
.slider-wrapper.fullWidth .carousel-button-group {
  max-width: 95%;
}
.slider-wrapper.hideButtons .carousel-button-group {
  display: none;
}
@media (max-width: 768px) {
  .slider-wrapper.hideButtonsOnMobile .carousel-button-group {
    display: none;
  }

  .swiper-btn-group {
    display: none !important;
  }
}

.carousel-button-group > * {
  pointer-events: all;
}

.carousel-btn {
  display: grid !important;
  justify-content: center;
  align-items: center;
  width: 33px !important;
  padding: 0 !important;

  background: #f5f5f5 !important;
  border-color: #e2e2e2 !important;
  border-radius: 100vw !important;
}
.carousel-btn:disabled {
  visibility: hidden !important;
}

.swiper-btn.carousel-btn:disabled {
  visibility: visible !important;
}

.slider-wrapper.fullWidth .carousel-btn {
  position: absolute;
}

.carousel-btn img {
  width: 10px;
}
.carousel-prev {
  left: 0;
}
.carousel-next {
  right: 0;
}

.slider-wrapper {
  --slider-mobile-width: 94vw;
  width: var(--slider-mobile-width);
  margin: 0 calc(50% - var(--slider-mobile-width) / 2) 1rem;
  padding: 0;
}

@media (min-width: 576px) {
  .slider-wrapper {
    width: 100%;
    margin: 0;
  }
  .swiper {
    width: calc(100% - 45px);
    height: 100%;
  }
}

.slider-wrapper.fullWidth {
  width: 100%;
  margin: 0;
}

.slider-wrapper .react-multi-carousel-list {
  /* Allows box-shadow to show up on hover/click */
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin: 0 1.5rem; /* Prevents buttons from overlapping */
}
.fishery-slider.slider-wrapper .react-multi-carousel-list,
.homepage-catch-slider.slider-wrapper .react-multi-carousel-list {
  margin: 0;
  margin-right: 1.5rem;
}
.fishery-slider .carousel-next,
.homepage-catch-slider .carousel-next {
  right: 20px;
}
.fishery-slider .slider-content {
  position: relative;
  border-radius: 20px !important;
}
.fishery-slider .fishery-name {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  padding: 10px 18px;
  color: #fff;
  font-family: 'gSemiBold';
  font-size: 26px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 81.5%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.slider-wrapper.partialNext .react-multi-carousel-list {
  margin-right: 2.5rem;
}

.slider-wrapper.fullWidth .react-multi-carousel-list,
.slider-wrapper.hideButtons .react-multi-carousel-list {
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .slider-wrapper.hideButtonsOnMobile .react-multi-carousel-list {
    padding: 0;
    margin: 0;
  }
}

.slider-wrapper .slick-arrow.slick-prev,
.slider-wrapper .slick-arrow.slick-next {
  margin: 0 !important;
}

/*.slider-wrapper .carousel-button-group .carousel-btn {*/
/*  top: 45%;*/
/*}*/

/*@media (min-width: 465px) {*/
/*  .slider-wrapper.vertical .carousel-button-group .carousel-btn {*/
/*    top: 105px;*/
/*  }*/
/*}*/
.slider-pro-icon {
  position: absolute;
  top: 10px;
  color: white;
  right: 10px;
  font-family: 'Montserrat';
  padding: 0 20px;
  border-radius: 20px;
}
.slider .slider-content {
  height: 100%;
  transition: all 0.5s;
  margin: 0 15px; /* Controls arrow button placement */
  border-radius: 1rem;
  overflow: hidden;
}
.fishery-slider .slider-content,
.homepage-catch-slider .slider-content {
  height: 100%;
  transition: all 0.5s;
  margin: 0; /* Controls arrow button placement */
  border-radius: 1rem;
  overflow: hidden;
}
.homepage-catch-slider .slider-content {
  height: auto;
  border: none;
}
.homepage-catch-slider .carousel-button-group {
  height: calc(100% - 65px);
}
.homepage-catch-slider .slider-image {
  aspect-ratio: 13/11;
}
.catch-slider .slider-card,
.catch-slider .slider-content {
  border: #e2e2e2 solid 1px;
}

.slider-wrapper.limit-height .react-multi-carousel-track {
  max-height: 22rem;
}

.slider-wrapper.fullWidth .slider .slider-content {
  margin: 0;
  border-radius: 0;
}

.slider-wrapper:not(.fullWidth):not(.noHover) .slider .slider-content:hover {
  transform: scale(1.03);
  box-shadow: 0 3px 4px 1px rgb(0 0 0 / 29%);
}
.swiper-wrapper {
  margin: 20px 0;
}
.slider-wrapper:not(.fullWidth):not(.noHover)
  .swiper-wrapper
  .swiper-slide:hover {
  transform: scaleY(1.03) !important;
  box-shadow: 0 3px 4px 1px rgb(0 0 0 / 29%);
}

.slider-wrapper .slider-extra {
  color: gray;
}

.slider-card {
  background: #fafafa;
  margin: 0;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.slider-card.avatar,
.slider-card.lake {
  background: white;
  border: #e2e2e2 solid 1px;
}

.slider-card a,
.slider-card a:hover,
.slider-card a:focus {
  color: rgb(0, 0, 0, 0.85);
}

.slider-card.horizontal > * {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}
.cloud-cover {
  font-size: 4em;
}
.pressure-text {
  font-size: 3em;
}
@media (max-width: 576px) {
  .swiper-version.slider-wrapper {
    --slider-mobile-width: 93vw;
    width: var(--slider-mobile-width);
    margin: 0 calc(50% - var(--slider-mobile-width) / 2) 1rem 0;
    padding: 0;
  }
}
@media (max-width: 350px) {
  .slider-card.horizontal > * {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 450px) {
  .fishery-slider.slider-wrapper .react-multi-carousel-list,
  .homepage-catch-slider.slider-wrapper .react-multi-carousel-list {
    margin: 0;
  }
  .cloud-cover {
    font-size: 3em;
  }
  .pressure-text {
    font-size: 2em;
  }
}

.slider-card.horizontal > * > * {
  height: 100%;
  width: 100%;
}

.slider-card.horizontal .slider-image {
  aspect-ratio: 3/2;
}

.slider-card.horizontal .slider-card__content {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
}

.slider-card.avatar > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.slider-image {
  position: relative;
  object-fit: cover;
  background-size: cover;
  object-position: center center;
  background-position: center center;
  background-color: #464646;
  min-height: 150px;
  aspect-ratio: 7/5;
  width: 100%;
  margin: 0 auto;
}

@supports not (aspect-ratio: 7/5) {
  .slider-image {
    max-height: 10vh;
    min-height: 100%;
  }
}

.slider-avatar {
  aspect-ratio: 1;
  flex-shrink: 0;
}

.image_slider__clickable {
  cursor: pointer;
}

.image_slider_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.avatar-card-header {
  display: flex;
  flex-direction: row;
  column-gap: 1.25rem;
  row-gap: 0.1rem;
}

.avatar-card-header__content {
  display: flex;
  flex-direction: column;
  gap: inherit;
}

.slider-card__avatar__content {
  display: flex;
  flex-direction: column;
}

.review-slider-preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: 100%;
}
.wind-compass-container {
  position: relative;
  height: 120px;
  width: 120px;
  background-image: url(../image/BG-COMPASS.svg);
}
.wind-compass-container .arrow {
  position: absolute;
  left: calc(50% - 8px);
}
.wind-compass-container .direction {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  color: #d9eeff;
  font-size: 24px;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translate(-50%, -50%);
}

@supports (-webkit-line-clamp: 5) and (-webkit-box-orient: vertical) and
  (display: -webkit-box) {
  .review-slider-preview {
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.slider-count {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.25rem 0.5rem;
  background: #00000099;
  margin: 1rem;
  border-radius: 5px;
  z-index: 2;
  color: white;
}
.catch-details .info-text {
  font-size: 18px;
  word-break: break-word;
}
.catch-report-large-box .large-text {
  font-size: 34px;
}
.catch-report-large-box .detail-box {
  padding: 0 20px;
}
.backdrop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  background: #d9eeff1c;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: 30px;
}

.backdrop-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  z-index: 1000;
}

.backdrop-overlay-text button {
  background-color: #1b734e;
  color: white;
  border: 1px solid #d9eeff;
  border-radius: 15px;
  padding: 12px 24px;
  cursor: pointer;
  margin-top: 12px;
}
@media (max-width: 450px) {
  .backdrop-overlay-text {
    left: 25%;
    transform: translate(-16%, -50%);
  }
  .catch-details .info-text {
    font-size: 16px;
    word-break: break-word;
  }
  .catch-report-large-box .large-text {
    font-size: 28px;
  }
  .catch-report-large-box .detail-box {
    padding: 0 10px;
  }
}
