/* Main */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px !important;
  box-shadow: 1px 1px 1px #c0c0c0;
  position: fixed;
  width: 100%;
  background: white !important;
  transition: all 0.3s;
  z-index: 22;
  pointer-events: none;
}

.header > * {
  width: 100%;
  pointer-events: all;
}

.main-body,
.main-container,
.main-footer {
  background: #fff;
}

.main-body {
  margin: 5.3em 0px 0px 0px;
  padding: 0 0px;
}

.main-footer {
  text-align: center;
}

.profile_layout {
  background: white !important;
}

.profile_layout__header {
  height: fit-content !important;
  z-index: 2;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background: transparent !important;
  padding-top: 2rem !important;
}

.profile_layout__header--overlap-header {
  position: absolute;
}

.profile_layout__header__buttons {
  display: flex;
  gap: 1rem;
}
@media screen and (min-width: 576px) {
  .profile_layout__header__buttons,
  ._header_main_content .profile_layout__header__back_btn {
    display: none !important;
  }
}

.profile_layout .ant-btn-circle.ant-btn-icon-only {
  display: grid;
  place-content: center;
}

._header_main_content .profile_layout__content {
  min-height: 100vh !important;
}

/* Header */
.headerSubMenu .ant-menu-sub {
  box-shadow: 2px 2px 10px #5d5d5d !important;
  border-radius: 0.8em !important;
}

/* Header search */
.header-search
  .ant-input-wrapper.ant-input-group
  .ant-input-group-addon
  button {
  border-radius: 1em;
  width: 2em;
  padding: 0;
  height: 2em;
  z-index: 1;
}

.header-search .ant-select-selection-search .ant-input-group input {
  border-radius: 2em;
  border: 1px solid #e6e6e6;
}

.header-search .ant-select-selection-search .ant-input-group-addon {
  position: relative;
  right: 2.5em;
  background: none;
  height: 2em;
  z-index: 1;
}

#search-button-icon {
  background: #1e5295;
  color: white;
  border-radius: 2em;
  padding: 6px 8px;
  font-size: 1.2em;
}

input:focus {
  outline: none !important;
}

#header-menu-right {
  background: white;
  border-color: #e6e6e6;
  border-radius: 2em;
  width: 7em;
  height: 3em;
  box-shadow: none;
}

#header-menu-right .anticon-menu {
  color: black;
}

#menu-open-icon {
  /* padding-left: 10px;
	width: 2.4em; */
  padding-right: 15px;
  width: 2.8em;
}

#mainmenu-popover-right {
  border-radius: 1em;
  max-width: 270px;
  /* position: relative; */
  padding: 10px 0px;
  position: absolute;
  right: 0px;
}

#mainmenu-popover-right #main-menu .ant-menu-item {
  margin: 0px !important;
}

#mainmenu-popover-right ul li {
  padding: 0px 20px;
  /* background: #b3b3b3; */
  /* border-radius: 15px; */
  /* margin-top: 5px !important; */
}

#mainmenu-popover-right div.login-menu-items {
  padding: 0px 20px;
}

#main-menu {
  border: none;
}

#main-menu li a,
#main-menu li p,
#main-menu-mobile .main-menu-mobile__item a {
  color: #0d0d0dc4;
}

#main-menu li a:hover,
#main-menu li p:hover,
#main-menu-mobile .main-menu-mobile__item a:hover {
  color: #2772af;
}

/* Mobile menu */

#main-menu-mobile-page-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  background: transparent;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
}

#main-menu-mobile-page-wrapper * {
  pointer-events: initial;
}

#main-menu-mobile {
  width: fit-content;
  overflow: hidden;
  max-width: 80%;

  /* max-height and padding are actually set by onscroll handler in FixedMobileMenu.js */
  max-height: 5rem;
  padding: 0.25rem 2rem;
  transition:
    max-height 400ms ease,
    padding 400ms ease;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  gap: 1.5rem;

  background: white;
  border-radius: 5rem;
  box-shadow:
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.main-menu-mobile__item {
  min-width: 3rem;
  height: 100%;
}

.main-menu-mobile__item > * {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 1.5; /* Controls menu text padding */
  padding: 0.5rem 0;
}

#main-menu-mobile-page-wrapper .in-menu-icon {
  color: #2772af;
  margin-bottom: 0.25rem;
  padding: 0;
  width: 1.4rem;
  font-size: 1.2rem;
}

.header-md {
  justify-content: center;
  align-items: center;
}

.header-menu-right-md,
.header-md,
.header-menu-right-md + .menu-event-listener #mainmenu-popover-right {
  display: none !important;
}

.catch_report__drawer .ant-drawer-content-wrapper {
  width: 100% !important;
  max-width: min(36rem, 100vw);
}

.catch_report__drawer .ant-drawer-header {
  display: none;
}

.catch_report__drawer .ant-layout-header {
  width: fit-content;
}

.catch_report__drawer .ant-drawer-body {
  padding: 0;
}
.bite-condition-container {
  border: 4px solid #1f5b8c;
  background-color: #d9eeff;
  padding: 20px 10px;
  width: 100%;
}

#PB-button .ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}

#PB-button .ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}

#PB-button
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
  background-color: #00a36a !important;
  z-index: 0;
}

.catch-report-image-display {
  width: 100%;
  height: 12rem;
  min-height: fit-content;
  margin-bottom: 20px;
  overflow: hidden;
  object-fit: cover;
}

.catch-report-image-display div,
.catch-report-image-display .ant-upload-picture-card-wrapper {
  height: 100%;
}

.catch-report-image-display .ant-upload-select div {
  height: fit-content;
}
.catch-report-image-display .ant-upload-list-picture-card-container,
.catch-report-image-display
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 100% !important;
  height: 100% !important;
}
.picture-checkbox {
  flex-direction: row-reverse;
  justify-content: center;
}

.picture-checkbox .ant-checkbox-wrapper {
  order: 2;
}

.picture-checkbox .ant-checkbox-inner {
}

.picture-checkbox .ant-checkbox + span {
  order: 1;
  align-self: center;
}
.catch-report-upload .ant-upload-list-item-actions a:first-child {
  display: none;
}

@media (min-width: 576px) {
  #main-menu-mobile {
    display: none;
  }

  .header-menu-right-md {
    display: inline-block !important;
  }

  .header-md {
    display: flex !important;
  }

  .header-menu-right-md + .menu-event-listener #mainmenu-popover-right {
    display: block !important;
  }

  ._header_main_content .profile_layout__content--overlap-header {
    margin-top: 75px; /* Height of header */
  }
}

/* Mobile Drawer Menu*/

.drawer-menu-mobile .ant-drawer-body {
  background: #2772af;
  color: white;
  font-family: 'gLight', sans-serif;
  display: flex;
  flex-direction: column;
}

.drawer-menu-mobile__header button {
  height: fit-content;
  display: flex;
  margin-left: auto;
}

.drawer-menu-mobile__contents {
  flex-grow: 1;
}

.drawer-menu-mobile__footer {
  margin-top: 1rem;
  height: fit-content;
}

.drawer-menu-mobile__footer img {
  width: 5rem;
  max-width: 90%;
}

.drawer-menu-mobile .ant-menu,
.drawer-menu-mobile .ant-menu a {
  background: inherit;
  min-width: fit-content;
  width: 100%;
  color: white;
  border: none;
  font-size: 22pt;
}

.drawer-menu-mobile .ant-menu .ant-menu-item,
.drawer-menu-mobile .ant-menu a {
  border-radius: 8px;
  height: fit-content;
}

.drawer-menu-mobile .ant-menu a {
  padding: 4px;
  white-space: normal;
}

.drawer-menu-mobile .ant-menu a:hover {
  background: #e6f7ff;
  color: #1e5295;
}

.drawer-menu-mobile .ant-menu-item:not(:last-child) {
  margin-bottom: 0.5rem !important;
}

.drawer-menu-mobile .login-menu-items .login-menu-items__sub-heading {
  font-size: 18pt;
}

.drawer-menu-mobile
  .login-menu-items
  .ant-form-item:not(.ant-form-item-has-error) {
  margin-bottom: 1rem;
}

.drawer-menu-mobile .login-menu-items a {
  color: inherit;
  text-decoration: underline;
  font-size: 14pt;
}

.drawer-menu-mobile .login-menu-items input {
  font-size: 16pt;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.mobile-login-btn,
.mobile-logout-btn {
  display: block !important;
  margin: 0 auto;
  height: fit-content !important;
  padding: 0.3rem 1rem !important;
  font-size: 19pt !important;
  border-radius: 2rem !important;
  border-color: white !important;
  font-family: 'gSemiBold', sans-serif;
  text-transform: uppercase;
}

.mobile-login-btn {
  width: 80%;
  background: #2d4e69 !important;
}

.mobile-logout-btn {
  width: 100%;
  color: white !important;
  background: #be5454 !important;
}

.drawer-menu-mobile .in-menu-icon {
  color: inherit;
  width: 2.5rem;
  margin-right: 0.5rem;
}

.drawer-menu-mobile .ant-divider,
.drawer-menu-mobile .ant-menu-item-divider {
  color: white;
  background-color: white;
  border-color: white;
  margin: 24px 0 !important;
}

/* */
#admin-content .lake-manager-content {
  background: white;
  padding: 10px 4% 20px;
  height: fit-content;
}
.admin_perk_select .ant-select-selector {
  border-radius: 5px !important;
}
.admin_perk_select > tr > th {
  padding: 0;
}

.sb-admin-table th,
.sb-admin-table td {
  border: 0;
  border-bottom: 1px solid #bdbdbd !important;
  background-color: white !important;
  padding: 0 !important;
  font-family: 'gSemiBold';
}

.sb-admin-table th:before,
.sb-admin-table td:before {
  background: none !important;
}

.sb-admin-table td.ant-table-cell {
  padding: 15px !important;
}

@media (min-width: 768px) {
  #admin-content .lake-manager-content {
    padding: 40px 3% 20px;
  }
}
#mainmenu-popover-right .ant-popover-inner-content {
  padding: 10px 0px;
}

#mainmenu-popover-right #main-menu .ant-row.ant-form-item {
  margin-bottom: 15px;
}

/* #mainmenu-popover-right .ant-popover-inner-content li:hover{} */

#mainbody-overlay {
  height: 100%;
  position: fixed;
  z-index: 20;
  background: #0000009c;
  width: 100%;
}

#home-logo {
  width: 17%;
  min-width: 100px;
}

.in-menu-icon {
  padding-right: 10px;
  width: 25px;
  color: #2772af;
}

.sb-slider-carousel .slick-arrow.slick-prev {
  z-index: 1;
  left: 5px;
}

.sb-slider-carousel .slick-arrow.slick-next {
  z-index: 1;
  right: 5px;
}

.sb-slider-carousel .slick-dots {
  position: absolute;
  bottom: 8px;
}

.sb-slider-carousel img {
  height: 200px;
}

.sb-footer {
  position: relative !important;
  bottom: 0 !important;
  width: 100%;
  display: block;
  text-align: center;
  z-index: 2;
  font-size: 15px;
  padding-top: 3rem !important;
  padding-bottom: 130px !important; /* Allows all content to be above menu bar */
}

@media (min-width: 576px) {
  .sb-footer {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

#sb-header-logo-col {
  /*padding-left: 20px;*/
}

#sb-header-menu-col {
  /*padding-right: 20px;*/
}

@media screen and (max-width: 575px) {
  #sb-header-search-wrapper {
    flex-grow: 1;
    max-width: unset;
    min-width: 0;
    margin-right: 1rem;
  }
}

._header_main_content {
  min-height: 100vh !important;
}

#sb-footer {
  padding: 3% 0 2%;
  text-align: left;
  color: white;
}

#sb-footer h2 {
  font-size: 20px !important;
}

#sb-footer .footer-text {
  width: 75%;
  font-size: 14px;
}

#sb-footer .col2 p,
.col4 p,
.col5 p {
  margin-bottom: 5px;
}

#sb-footer .footer-address {
  margin-bottom: 0px;
  font-size: 11px;
}

#sb-footer .col2 p a,
.col4 p a,
.col5 p a {
  font-size: 13.5px;
}

#sb-footer .col2 p a:hover,
.col3 p a:hover,
.col4 p a:hover,
.col5 p a:hover {
  color: #ff9090;
}

.sb-footer__logos_container {
  display: flex !important;
  flex-direction: column;
}

.sb-footer__app_links_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
}
.catch-report-select .ant-select-selector {
  height: 36.5px !important;
}

.catch-report-weight-input {
  height: 36.5px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 12px;
}
.sandhurst-angler-details {
  background-color: #f3f3fe;
  min-height: 100vh;
  padding: 20px 10px;
}
.sandhurst-angler-details .ant-form-item {
  margin: 0;
}
.sandhurst-angler-details .box {
  margin: auto;
  max-width: 700px;
}
@media (min-width: 992px) {
  .sb-footer__app_links_container {
    display: none;
  }
}

.sb-footer__app_link:not(:last-of-type) {
  margin-right: 0.5rem;
}

.sb-footer__app_link img {
  max-width: 100%;
  height: 44px;
}

.sb-footer__external_logos_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 1rem;
}

.sb-footer__hide_on_mobile_tablet {
  display: none;
}

@media (min-width: 992px) {
  .sb-footer__hide_on_mobile_tablet {
    display: block;
  }

  .sb-footer__hide_on_large {
    display: none;
  }
}

.sb-footer__external_logo {
  background: white;
  flex: 1 1 6rem;
  max-width: 7rem;
  height: 5rem;
  padding: 0.25rem;
  overflow: hidden;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sb-footer__external_logo:not(:last-of-type) {
  margin-right: 1rem;
}

.sb-footer__external_logo img {
  max-width: 100%;
  max-height: 100%;
}

.trustpilot_widget {
  width: 100%;
  max-width: 10rem;
  border-radius: 8px;
}

@media (min-width: 576px) {
  .trustpilot_widget {
    max-width: 8.5rem;
  }
  .sb-copyright {
    font-size: 10px;
    margin-top: 50px;
  }
}
.sb-copyright {
  font-size: 10px;
}

.footer-contact-img {
  display: inline-block;
  width: 35px;
}

.footer-contact-img img {
  height: 22px;
  font-size: 17px;
}

.data-loader-image {
  display: block;
  width: 100%;
  padding: 0px !important;
}

.lake-image {
  width: 130px;
  float: left;
}

.lake-meta {
  float: left;
}

.lake-meta p {
  margin-bottom: 0px;
}

.disabled-swim-date {
  text-decoration: line-through;
}

.three-musketeers-col {
  padding: 10px;
}

.three-musketeers-col a {
  border-radius: 10px;
  box-shadow: 0px 1px 4px #5d5d5d !important;
  transition: all 0.4s ease;
  color: black;
  display: block;
  padding-bottom: 5px;
}

.three-musketeers-col a:hover {
  transform: scale(1.025);
  color: black;
}

.three-musketeers-col img {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.three-musketeers-title {
  padding: 5px 0px 0px 10px;
}

.three-musketeers-description {
  padding: 0px 0px 0px 10px;
}

.main-menu-custom {
  background: white;
  color: black;
  width: 250px;
  z-index: 100;
  /* right: 145px;
	top: 15px; */
  border: 1px solid #00000014;
}

.main-menu-custom #main-menu {
  border-radius: 10px;
}

@media only screen and (max-width: 500px) {
  #withLogo {
    margin: 0px;
  }
}

@media only screen and (max-width: 350px) {
  #header-menu-right {
    width: 6em;
  }

  .home-page-last-content-img-right {
    width: 64px;
  }
}

.angler-login-email-field,
.angler-login-password-field {
  padding-left: 6px !important;
}

.full-page-loading-icon {
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 50%;
    left: 58%; */
  z-index: 10001;
  background: #ffffffa6;
  width: 100%;
  height: 100vh;
}

.content-loading-icon {
  position: relative;
  left: 50%;
}

.content-loading-icon.search-loading-icon {
  position: absolute !important;
}
