@font-face {
  font-family: 'gLight';
  /* src: local('gLight'), url(assets/fonts/gilroyLight.otf); */
  src:
    local('gLight'),
    url(assets/fonts/gilroyLight.woff) format('woff');
}

@font-face {
  font-family: 'gBold';
  /* src: local('gBold'), url(assets/fonts/giloryBold.otf); */
  src:
    local('gBold'),
    url(assets/fonts/giloryBold.woff) format('woff');
}

@font-face {
  font-family: 'gSemiBold';
  /* src: local('gSemiBold'), url(assets/fonts/gilroySemiBold.otf); */
  src:
    local('gSemiBold'),
    url(assets/fonts/gilroySemiBold.woff) format('woff');
}

@font-face {
  font-family: 'gMedium';
  /* src: local('gSemiBold'), url(assets/fonts/gilroySemiBold.otf); */
  src:
    local('gMedium'),
    url(assets/fonts/gilroyMedium.woff) format('woff');
}

@font-face {
  font-family: 'gRegular';
  /* src: local('gSemiBold'), url(assets/fonts/gilroySemiBold.otf); */
  src:
    local('gRegular'),
    url(assets/fonts/gilroyRegular.woff) format('woff');
}

.gSemiBold {
  font-family: 'gSemiBold', sans-serif;
}

.gLight {
  font-family: 'gLight', sans-serif !important;
}

.gMedium {
  font-family: 'gMedium', sans-serif;
}

.gRegular {
  font-family: 'gRegular', sans-serif !important;
}

.gBold {
  font-family: 'gBold', sans-serif;
}
.font-montserrat {
  font-weight: 900;
  font-family: Montserrat, 'gBold', sans-serif;
}

:root {
  --content-max-width: 1300px;
}

#root {
  position: relative;
}
.ant-col {
  min-height: 0px !important;
}
/* 
ul, ol{
  list-style: none;
} */

.cluster-marker {
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-size: 13px;
  font-family: 'gBold', sans-serif;
}

.fishery-marker {
  background: none;
  border: none;
}

.fishery-marker img {
  width: 25px;
}

.ant-message,
.ant-alert {
  font-family: 'gLight', sans-serif;
}

/* Reactive Map Search */

.main-container {
  display: flex;
  flex-direction: column;
}

.nav-container {
  position: fixed;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(79.09deg, #32b5f9 0%, #419ced 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
  height: 80px;
  padding: 0 10px 0 25px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-content-guest {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content-guest {
  display: block;
}

.dropdown:hover .button {
  background-color: #32b5f9;
  color: white;
}

.title {
  height: 45px;
  width: 201px;
  color: #ffffff;
  /* font-family: Lato; */
  font-family: 'gBold', sans-serif;
  font-size: 36px;
  font-weight: 900;
  line-height: 44px;
}

.filters-search-container {
  width: 100%;
  position: fixed;
  top: 80px;
  display: flex;
  z-index: 2;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.button {
  background-color: #ffffff; /* Green */
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  margin: 8px 20px;
  cursor: pointer;
  outline: none;
  height: 40px;
  width: 111px;
  border: 1px solid #7a7a7a;
  border-radius: 3px;
}

.filter-container {
  display: flex;
  flex-direction: row;
}

.search {
  width: 740px;
}

#home-top-menu {
  --featured-banner-height: 35px;
  --featured-banner-offset-height: var(--featured-banner-height);
}

#featured-banner {
  position: fixed;
  width: 100%;
  z-index: 2;
  height: var(--featured-banner-height);
  background-color: #252929;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 576px) {
  #home-top-menu {
    --featured-banner-offset-height: 0;
  }

  #featured-banner {
    position: relative;
  }
}
#home-top-menu .ant-layout-header.homepage-styles {
  background: #fff !important;
  position: fixed !important;
  padding: 25px 12px 20px !important;
  width: 100% !important;
  z-index: 2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02) !important;
}

#home-top-menu .ant-layout-header.small.header {
  background: white !important;
  box-shadow: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10px 12px 10px 20px !important;
  position: fixed;
  height: fit-content !important;
  width: 100%;
  z-index: 2;
  opacity: 0.96;

  margin-top: var(--featured-banner-offset-height);
}
#home-top-menu .ant-layout-header.small.top-header {
  background: transparent !important;
  color: white !important;
  box-shadow: none;
  border-radius: 0 0 10px 10px;

  padding: 10px 12px 10px 20px !important;
  position: fixed;
  height: fit-content !important;
  width: 100%;
  z-index: 2;
  /* position: absolute; */

  margin-top: var(--featured-banner-offset-height);
}
#home-top-menu .ant-layout-header.small.top-header a:hover {
  color: #d9eeff !important;
}
#home-top-menu .ant-layout-header.small.header a:hover {
  color: #174a73 !important;
}

#home-top-menu #featured-banner + .ant-layout .ant-layout-header.small.header {
  padding-top: 20px !important;
}

#home-top-menu #header-menu-right,
#home-top-menu .ant-layout.layout._header_main_content {
  background: white;
}

#home-top-menu header #sb-header-logo-col,
#home-top-menu header #search-full-box {
  display: none;
}

@media only screen and (min-width: 576px) {
  #home-top-menu header #sb-header-logo-col,
  #home-top-menu header #search-full-box {
    display: flex;
  }
}

.CookieConsent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  --mobile-cc-margin: 0.5rem;
  margin: var(--mobile-cc-margin);
  margin-bottom: calc(0.75rem + var(--mobile-cc-margin));
  max-width: calc(100% - 2 * var(--mobile-cc-margin));
  border-radius: 1rem;
}

@media screen and (max-width: 576px) {
  .CookieConsent.mobileTop {
    top: 0;
  }
  #home-top-menu .ant-layout-header.small.header {
    background: none !important;
    display: none;
  }
  #home-top-menu .ant-layout-header.small.header .desktop-header {
    display: none;
  }
  #home-top-menu .ant-layout-header.small.top-header {
    display: none;
  }
}

.CookieConsent > div:first-child {
  width: 100%;
  flex-basis: 100% !important;
  flex-shrink: 1 !important;
  justify-content: center;
  text-align: center;
}

.cookie-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 0.5rem;
  width: 100%;
  margin: 0 2rem 1rem 1rem;
}

.cookie-btn {
  font-size: 16px !important;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  min-width: 0;
  flex-shrink: 1;
  margin: 0 !important;
  flex-basis: 100% !important;
  flex-grow: 1 !important;
}

.cookie-accept-btn {
  background: #0174b5 !important;
  color: white !important;
  flex-grow: 1 !important;
}

.cookie-decline-btn {
  text-decoration: underline;
  background: none !important;
  color: rgb(0, 0, 0, 0.85) !important;
}

.cookie-accept-btn:hover {
  background: #4a895d !important;
}

.cookie-decline-btn:hover {
  color: #2772af !important;
}

.cookie_consent__badge {
  width: 100%;
  display: grid;
  place-content: center;
}

.cookie_consent__badge > img {
  min-height: 5rem;
  max-width: 5rem;
  margin-top: -3rem;
  margin-bottom: 1rem;
}

.cookie_consent__title {
  font-size: 23px;
}

.cookie_consent__text {
  font-size: 18px;
}

.cookie_consent__close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
}

.cookie_consent__close .ant-btn:not([disabled]):hover {
  color: #2772af;
  border-color: #2772af;
}

@media screen and (min-width: 576px) {
  .CookieConsent > div:first-child {
    margin: 15px 0 !important;
  }
  .CookieConsent {
    max-width: 22rem;
    height: fit-content !important;
    margin: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 1.5rem;
  }
  .cookie-btn-wrapper {
    margin: 0;
  }

  .cookie_consent__title {
    font-size: 19px;
  }

  .cookie_consent__text {
    font-size: 15px;
  }
  .CookieConsent.desktopCenter {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .CookieConsent.desktopRight {
    margin-left: auto;
    margin-right: 0;
    left: 0;
    right: 0;
  }
  .cookie_consent__badge {
    display: none;
  }
}

.create-sb-account-btn {
  background: #2e8b57;
  width: 270px;
  padding: 13px;
  text-align: center;
  color: white;
  font-size: 13px;
  font-family: 'gBold', sans-serif;
  border-radius: 10px;
  cursor: pointer;
}
.show_catch_report__drawer .ReactCollapse--collapse {
  transition: 0.8s height;
}
.fishery_profile__drawer .ant-drawer-content-wrapper,
.show_catch_report__drawer .ant-drawer-content-wrapper {
  width: 100% !important;
  max-width: min(37rem, 100vw);
}

.fishery_profile__drawer .ant-drawer-header,
.show_catch_report__drawer .ant-drawer-header {
  display: none;
}

.fishery_profile__drawer .ant-layout-header,
.show_catch_report__drawer .ant-layout-header {
  width: fit-content;
}

.fishery_profile__drawer .ant-drawer-body {
  padding: 0;
}
.show_catch_report__drawer .ant-drawer-body {
  padding: 0;
  background-color: #072640;
}
.main_container_padding {
  padding: 1rem 1.5rem !important;
}
.success_container {
  border-radius: 10px;
  margin: 1.5rem 1.5rem;
  width: calc(100% - 3rem);
  min-height: calc(100vh - 3rem);
  padding: 4rem 3rem;
}

.main_container_padding__content {
  max-width: var(--content-max-width) !important;
  margin: 0 auto;
  position: relative;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 768px) {
  .main_container_padding {
    padding: 1rem 2.5rem !important;
  }
}

.loadingContainer {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 80vh;
}

.map_location_pin {
  /* Puts the centre of the pin at the specified lat-lng coordinates origin (rather than the top left corner) */
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
========================================================
      Custom AntD Components
========================================================
*/

.custom_alert.ant-alert {
  background: none;
  border: none;
  padding: 0;
  gap: 0.75rem;
}

.custom_alert .ant-alert-message {
  color: #2772af;
  font-family: 'gMedium', sans-serif;
}

.custom_alert .ant-alert-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
}

.custom_alert .ant-alert-icon svg {
  font-size: 1.4rem;
}

.custom_result.ant-result {
  padding: 0;
}

.custom_result .ant-result-icon {
  margin-bottom: 1rem;
}

.custom_result .ant-result-title {
  margin-bottom: 1rem;
  font-family: 'gSemiBold', sans-serif;
}

.custom_result .ant-result-subtitle {
  font-size: 1.2em;
  line-height: 1.66;
  font-family: 'gMedium', sans-serif;
}

.custom_result.ant-result-success .ant-result-icon > .anticon {
  color: #32bea6;
}

.box-icon {
  position: relative;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  border-radius: 0.6rem;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.22em;

  color: white;
  background: #2772af;
}

a.ant-btn.antd-link-button-override {
  /* AntD a.ant-btn overrides */
  line-height: 1.5715 !important;
  padding-top: 10px !important;
}
@media screen and (max-width: 350px) {
  .cookie-btn-wrapper {
    column-gap: 0.5rem;
  }
  .success_container {
    padding: 2rem 1.5rem;
  }
}

@media screen and (min-width: 576px) {
  .cookie-btn-wrapper {
    column-gap: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .header-links a {
    display: none;
  }
}

.lg-checkbox .ant-checkbox-inner,
.lg-checkbox input {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.xl-checkbox .ant-checkbox-inner,
.xl-checkbox input {
  width: 25px !important;
  height: 25px !important;
  font-size: 25px;
}

.lg-checkbox .ant-checkbox-inner::after,
.xl-checkbox .ant-checkbox-inner::after {
  width: 35%;
  height: 68%;
  top: 48%;
  left: 24%;
}
