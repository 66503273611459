.home-quick-links {
  width: 100%;
  overflow: hidden;
}

.home-quick-links__option {
  color: #3f3f3f;
  background: #f7f7f7;
  cursor: pointer;
  flex-wrap: nowrap !important;
  gap: 1rem;
}

.home-quick-links__option > * {
  min-width: 0 !important;
}

@media screen and (max-width: 576px) {
  .home-quick-links__option .ant-list-item-extra {
    margin-bottom: auto !important;
  }
}

.home-quick-links__option:hover {
  background: #eaeaea;
  color: #323232;
}

.home-quick-links__icon {
  max-width: 0.8em;
}

.home-quick-links__link,
.home-quick-links__link:hover {
  color: inherit;
}
