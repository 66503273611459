.admin-login-page {
  display: flex;
  height: 100vh;
}

.login-inside-form-content {
  padding: 40px 0px !important;
}

#admin-login-form-content {
  margin: auto;
  width: 50%;
  min-width: 500px;
  background: white;
  border-radius: 15px;
}

#admin-login-form-content .admin-logo-text {
  position: relative;
  top: 6px;
}

.admin-login-form-wrapper {
  display: flex;
}

#admin-login-form-content .admin-login-form-items {
  margin: auto;
  width: 50%;
}

.admin-login-btn {
  width: 100%;
  border: none !important;
  border-radius: 5px !important;
  height: 45px !important;
  font-size: 19px !important;
}

#admin-login-form-content .admin-login-form-items input {
  background: #fcfcfc !important;
  margin-left: 0px;
}

#admin-login-form-content .admin-login-form-items input.email {
  height: 38px;
}

#admin-login-form-content .admin-login-form-items .admin-secret input {
  height: 28px;
}

#admin-login-form-content .contact-us-info-admin-login-wrapper {
  display: flex;
}

#admin-login-form-content .contact-us-info-admin-login-content {
  width: 50%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  #admin-login-form-content {
    margin: auto;
    width: 95%;
    min-width: 230px !important;
  }

  #admin-login-form-content .login-inside-form-content {
    padding: 40px 10px !important;
  }

  #admin-login-form-content .admin-login-form-items {
    min-width: 230px !important;
  }
}
