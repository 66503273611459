body {
  --user_booking_container__max_width: 768px;
}

#booking-container input[type='checkbox'] {
  cursor: pointer !important;
}

.booking-controls--stacked {
  display: grid;
  grid-template-columns: fit-content(90%) 1fr;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 374px) {
  .booking-controls--stacked {
    row-gap: 0.5rem;
    grid-template-columns: 1fr;
  }
}

.booking-controls--side_by_side {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

@media screen and (min-width: 992px) {
  .booking-controls--side_by_side {
    flex-wrap: nowrap;
  }
}

.booking-controls--side_by_side
  > *:not(.booking-controls--side_by_side__divider) {
  flex-grow: 1;
  flex-basis: 100%;
}

.booking-controls--side_by_side__divider {
  display: grid;
  place-content: center;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.booking-control__label {
  font-family: 'gSemiBold', sans-serif;
  min-width: min-content;
  font-size: 16px;
  margin-bottom: 0;
}

.booking-control__select {
  min-width: 5rem;
  font-size: 16px !important;
  max-width: 15rem;
}

@media screen and (max-width: 576px) {
  .booking-control__select {
    max-width: unset;
  }
}

.booking-control__select--checkbox_only {
  min-width: 2rem;
}

.booking-control__select .ant-select-selector {
  height: fit-content !important;
  border-radius: 10px !important;
  padding: 3px 11px !important;
}

/* Default css from calendar */
.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.Range .DayPicker-Day {
  border-radius: 0px;
}

.Range .DayPicker-Day:hover {
  border-radius: 0px;
}

/* .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
} */
/*  */

/* Calendar CSS */
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;
  background-color: black !important;
  color: #f0f8ff;
}

/* Selectable  */
.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: white !important;
  color: black;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f1f1f1 !important;
  color: black;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

/*  */

#booking-day-picker .DayPicker-Month {
  width: 50%;
  min-width: 230px;
  display: inline-block;
  margin: 0px;
  display: inline-block;
  float: left;
  font-size: 13px;
}

#booking-day-picker .DayPicker-Day {
  width: 30px !important;
  height: 30px !important;
  font-size: 13px;
  padding: 0px !important;
  outline: none;
}

.sb-selectors {
  display: inline-block;
}

.booking-date-picker {
  display: inline-block;
}

.booking-date-picker input {
  width: 120px;
  font-size: 11px;
  height: 47px;
  border-radius: 10px;
  border: 1px solid #c5c0c0;
  padding-left: 7px;
  padding-top: 10px;
}

.booking-date-picker .checkin-label,
.booking-date-picker .checkout-label {
  position: absolute;
  z-index: 2;
  font-size: 8px;
  top: 4px;
}

.booking-date-picker .checkin-label {
  left: 7px;
}

.booking-date-picker .checkout-label {
  left: 126px;
}

.DayPicker-Months {
  padding: 20px 0px;
  margin: 0px;
}

.booking-from-date-picker input {
  position: relative;
  z-index: 1;
}

button {
  outline: none !important;
}

/* Check-In CheckOut */

.sb-booking-date-display {
  width: 110px;
  display: inline-block;
  height: 45px;
  border: 1px solid #b3b3b3;
  padding: 6px 10px;
  border-radius: 5px;
}

.booking-label {
  /* position: absolute; */
  display: block;
  font-size: 11px;
  color: black;
  font-weight: 600;
}

.sb-booking-date {
  font-size: 11px;
  /* position: relative; */
  display: block;
  top: 10px;
  /* color: grey; */
}

.number-ticket-select {
  width: 35%;
  float: right;
}

.ad-no-of-rod {
  width: 50% !important;
}

.lake-select {
  width: 60%;
  float: right;
}

.ad-no-of-rod .ant-select-selector {
  border-radius: 5px !important;
}

.lake-select .ant-select-selector {
  border-radius: 7px !important;
  height: 30px !important;
  font-size: 11px;
  /* margin-left: 5px; */
  background: whitesmoke !important;
}

.lake-select .ant-select-selector input {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.number-ticket-select .ant-select-selector {
  border-radius: 7px !important;
  height: 30px !important;
  font-size: 11px;
  /* margin-left: 5px; */
  background: whitesmoke !important;
}

.number-ticket-select .ant-select-selector input {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.rdrDateRangePickerWrapper {
  float: left;
}

.bookings-slider-image {
  width: 19rem;
  max-width: calc(100% - 4rem); /* Prevents arrow buttons from overlapping */
  min-width: 0;
  aspect-ratio: 3 / 2;
  max-height: 11rem;
  cursor: pointer;
  object-fit: cover;
}

.lake-selection .bookings-slider-image:hover {
  border: 6px solid #0089ff;
}

.lake-selection.active .bookings-slider-image {
  border: 6px solid #0089ff;
}

.lake-selection .booking-slider-meta {
  color: grey;
}

.lake-selection.active .booking-slider-meta {
  color: #1e5295;
}

.booking-slider-meta {
  display: block;
  margin: auto;
  line-height: 1.25;
}

.booking-lake-title {
  margin-bottom: 7px;
  color: #505050;
}

.booking-lake-subtitle {
  font-weight: 600;
  color: #2c2b2b;
}

.booking-session-heading {
  background-color: #2772af;
  height: fit-content;
  min-height: 35px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 8px 0;
  font-family: 'gSemiBold', sans-serif;
  /* max-width: 400px !important; */
}

.fp-lake-info-tab {
  background-color: white;
  height: 35px;
  padding: 5px 10px;
  border-radius: 10px;
  font-family: 'gSemiBold';

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fp-lake-image-modal .ant-modal-body {
  padding: 0;
}

.fp-lake-image-modal .ant-modal-close {
  left: 0;
}

.booking-time-heading {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  background-color: #2772af;
  color: white;
  font-size: 16px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-family: 'gSemiBold', sans-serif;
}

.display-booking-time {
  float: left;
}

.select-timing {
  display: block;
  height: 45px;
  background: grey;
  border-radius: 15px;
  text-align: center;
  padding: 15px 0px;
}

.display-add-booking-time .ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.display-add-booking-time .ant-radio-button-wrapper {
  height: fit-content;
  padding: 10px;
  width: 100%;
  background: #f7f7f7;
  color: black;
  font-family: 'gSemiBold', sans-serif !important;
  font-size: 16px;
  text-align: center;
  border-radius: 0.75rem !important;
  border: 1px solid #dcdcdc !important;
}

.display-booking-time
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.display-add-booking-time
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.display-booking-time
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.display-add-booking-time
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #60b077;
}

.display-add-booking-time
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):not(
    .ant-radio-button-wrapper-checked
  ):hover {
  background: #e2e2e2;
  color: #000;
}

.display-add-booking-time .ant-radio-button-wrapper-disabled {
  color: #c15454 !important;
  background-color: #ff9f9f !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):before {
  background-color: white !important;
}

.ant-radio-button-wrapper {
  border: 1px solid #cccccc !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
  background: whitesmoke;
}

.booking-select-time {
  text-align: center;

  font-family: 'gSemiBold', sans-serif;
  font-size: 16px;
}

.mySwiper {
  height: 38vh;
}

@media screen and (max-width: 500px) {
  .fp-lake-image-modal .ant-modal-content {
    height: 100%;
  }

  .mySwiper {
    height: 30vh;
  }
  .booking-controls--stacked {
    grid-template-columns: 1fr;
  }
}

.admin-booking-select-time {
  text-align: center;
  font-size: 12px;
}

.select-time-info {
  font-family: 'gLight';
  font-size: 13px;
  background-color: #dbefff;
  border: 2px solid #2772af;
  border-radius: 10px;
  margin: 10px 0 20px 0;
  padding: 5px;
  max-width: 290px;
}

.lake-specific-info {
  font-family: 'gMedium';
  font-size: 15px;
  background-color: #dbefff;
  border: 2px solid #2772af;
  border-radius: 10px;
  margin: 10px 0 0;
  padding: 16px;
  /* max-width: 290px; */
}

.admin-ticket-info {
  background-color: #dbefff;
  border: 2px solid #4ea5ed;
  border-radius: 5px;

  padding: 5px;
  max-width: 290px;
}

.admin-ticket-info--desktop {
  display: none;
}

.admin-ticket-info--mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .admin-ticket-info--desktop {
    display: block;
  }

  .admin-ticket-info--mobile {
    display: none;
  }
}

.disabled-time-btn {
  width: 106px;
  height: 35px;
  border-bottom: 1px solid #b3b3b3;
  position: absolute;
  top: -37px;
  left: -12px;
  -webkit-transform: translateY(22px) translateX(8px) rotate(-14deg);
}

#dynamic_anglerform_nest_item .ant-form-item-label {
  text-align: left !important;
}

.booking-summary-date .sb-booking-date-display {
  width: 130px;
  height: 53px;
}

.booking-summary-date .sb-booking-date-display .booking-label {
  font-size: 10px;
  top: 8px;
}

.booking-summary-date .sb-booking-date-display .sb-booking-date {
  font-size: 12px;
  top: 15px;
}

#summary-notice {
  border-left: 20px solid #f9ab5b;
  box-shadow: 2px 2px 10px #969696;
  /* height: 150px; */
  padding: 5px 15px;
}

.summary-swims {
  color: #464646;
}

.mobile-summary-total {
  background-color: #1e5295;
  position: fixed !important;
  bottom: 0;
  height: fit-content;
  padding: 0.75rem 0.4rem 0.75rem;
  width: 100vw;
  text-align: center;
  display: block;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .mobile-summary-total {
    display: none;
  }
}

.summary-total-meta {
  float: left;
  width: 60%;
}

.summary-total-amount {
  float: right;
}

.summary-total-amount p {
  font-size: 1.5em;
}

#summary-notice ul {
  padding: 0px 0px 0px 15px !important;
}

#summary-notice ul li {
  margin: 3px 0px;
  color: #585858;
}

/* .notice-list{
  margin: 3px 0px;
  color: #585858;
} */

#summary-notice ul li a {
  color: #585858;
  text-decoration: underline;
}

.select-count-images {
  width: 45px;
  float: right;
}

.display-select-counter p {
  height: 45px;
}

.display-select-counter p span {
  font-size: 25px;
  color: #585858;
}

/* Steps Button */
.steps-action {
  text-align: center;
}

.steps-action button {
  text-align: center;
  height: 47px;
  width: 180px;
  border-radius: 20px;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.steps-action .booking-back-btn {
  background: grey;
}

.steps-action .booking-next-btn {
}

.steps-action .booking-finish-btn {
}

/* Step 2 */
.image-gallery-thumbnails-wrapper {
  max-height: 480px;
  margin: 0px !important;
}

.image-gallery-thumbnails {
  /* overflow: scroll !important; */
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.image-gallery-slides {
  height: 20rem;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.image-gallery-slides * {
  height: 100%;
}

.image-gallery-slide img {
  object-fit: cover !important;
}

.image-gallery-thumbnail,
.image-gallery-thumbnail:active {
  border: 0px !important;
  margin-top: 0px !important;
}

.image-gallery-thumbnail-image {
  height: 80px !important;
}

/* Step 2 Availability table */
.tb-green {
  background: green;
}

.tb-red {
  background: red;
}

.tb-black {
  color: black;
}

.tb-yellow {
  background: yellow;
}

.sticky-table-table {
  width: 100%;
}

.available-swim-date {
  cursor: pointer;
}

.select-swim-user-image {
  width: 14px;
}

.select-swim-user-image.active-double {
  width: 20px;
}

/* .available-swim-date:hover{
  color: blue;
} */

.sticky-table-cell {
  text-align: center;
}

/* Step 3 */

.booking-inputs {
  display: inline-block;
  margin-top: 5px;
  width: 50%;
  height: 45px;
}

.booking-inputs input {
}

.swim-checkbox input {
  min-width: 0px !important;
  max-width: 14px !important;
}

#dynamic_form_nest_item input:not(.input-swim-common) {
  border-radius: 10px;
  width: 260px;
  max-width: 300px;
  min-width: 150px;
}

#dynamic_anglerform_nest_item input,
#dynamic_anglerform_nest_item .ant-select {
  border-radius: 10px;
  margin-left: 0;
  max-width: 300px;
}

.terms-title {
  font-size: 18px;
}

.terms-divider {
  margin: 0px 0px 20px 0px !important;
}

.ant-row.ant-form-item input {
  margin-left: 6px;
}

/* CSS for Email and Tel anglers */
/* #dynamic_anglerform_nest_item_anglers_0_email,
.sb-anglers-email input {
  margin-left: 0px !important;
}

.sb-anglers-tel-no input {
  margin-left: 0px !important;
} */

#dynamic_anglerform_nest_item_anglers_0_tel {
  margin-left: 51px !important;
}

#submit-form {
  position: absolute;
  top: 63px;
  width: 250px;
  height: 45px;
  border-radius: 20px;
  z-index: 1;
  margin-left: 8px;
  font-family: 'gSemiBold';
  font-size: 20px;
}

#summary-dates {
  float: right;
}

#booking-lake-select .carousel-next,
#booking-lake-select .carousel-prev {
  background: white !important;
  border: 1px solid #e6e6e6 !important;
}

#booking-lake-select .carousel-btn img {
  width: 7px;
}

#booking-lake-select .carousel-btn:hover {
  background: #dedede !important;
}

#booking-angler-info-form .ant-checkbox-wrapper span {
  color: #777777;
}

/* 
#booking-angler-info-form label{
  font-size: 16px;
} */

.sb-special-layout {
  position: relative;
  left: 4px;
}

.user-booking-step-actions .booking-back-btn {
  margin: 0px 6% 0px -3%;
  float: none !important;
}

@media only screen and (max-width: 576px) {
  #dynamic_anglerform_nest_item input,
  #dynamic_anglerform_nest_item .ant-select {
    max-width: 95%;
  }
}

.user-booking-summary__header {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 28px;
  padding: 1.6rem;
}

.user-booking-summary__header__logo {
  display: none;
}

.user-booking-summary__masthead {
  max-width: 100%;
  height: fit-content;
  position: relative;
}

.user-booking-summary__masthead__content {
  width: 100%;
  min-height: fit-content;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (min-width: 768px) {
  .user-booking-summary__header {
    font-size: 30px;
    justify-content: space-between;
    padding: 2rem;
  }

  .user-booking-summary__header--async-payment {
    justify-content: center;
  }

  .user-booking-summary__header__logo {
    display: block;
  }

  .user-booking-summary__masthead {
    border-radius: 5px;
  }

  .user-booking-summary__masthead--top-padding {
    margin-top: 15px;
  }

  .user_booking_outer_container {
    min-height: 100vh;
    background: #e7e7e7;
    display: grid;
    place-content: center;
  }

  .user_booking_container--max-width {
    margin: 1rem;
    width: var(--user_booking_container__max_width);
    max-width: 100%;
    border-radius: 1rem;
    overflow: hidden;

    --user_booking_container__padding: 5rem;
  }

  .user_booking_container--max-width > *:not(.user-booking-summary__header) {
    margin-left: var(--user_booking_container__padding);
    margin-right: var(--user_booking_container__padding);
    position: relative;
  }

  .user_booking_container--max-width > .user-booking-summary__header {
    padding-left: var(--user_booking_container__padding) !important;
    padding-right: var(--user_booking_container__padding) !important;
  }
}
